// Core
import { all, takeEvery } from 'redux-saga/effects';
// Types
import { types } from '../types';
import { asyncTypes } from './asyncTypes';
// Workers
import {
  callSetToLocalDataWorker, callPutSettingsWorker,
} from './workers';


function* watchSetCurrentPage() {
  yield takeEvery(types.SET_SPECIAL_PRICES_CURRENT_PAGE, callSetToLocalDataWorker);
}
function* watchSetPageSize() {
  yield takeEvery(types.SET_SPECIAL_PRICES_PAGE_SIZE, callSetToLocalDataWorker);
}
function* watchSetFilters() {
  yield takeEvery(types.SET_SPECIAL_PRICES_FILTERS, callSetToLocalDataWorker);
}
function* watchSetSorting() {
  yield takeEvery(types.SET_SPECIAL_PRICES_SORTING, callSetToLocalDataWorker);
}
function* watchPutSettingAsync() {
  yield takeEvery(asyncTypes.PUT_SPECIAL_PRICES_SETTINGS_ASYNC, callPutSettingsWorker);
}

export function* watchersSpecialPrices() {
  yield all([
    watchSetCurrentPage(),
    watchSetPageSize(),
    watchSetFilters(),
    watchSetSorting(),
    watchPutSettingAsync(),
  ]);
}
