import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import Link from '@mui/material/Link';
import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { uk } from 'date-fns/locale';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { PartnerSingleTariffHistoryModal } from './PartnerSingleTariffHistoryModal';
import ModalConfirm from '../../../containers/Modals/ModalConfirm';
import { api } from '../../../../engine/config/api';
import { setErrorMessage, setSuccessMessage } from '../../../../engine/core/ui/saga/asyncActionNotificationMessages';
import { HistoryIcon as LightHistoryIcon } from '../../../icons/HistoryIcon';
import { ButtonWithTooltip } from '../../../components/Buttons/ButtonWithIcon/Light/ButtonWithTooltip';
import Loading from '../../../components/Loading/Loading';

export default function AlloExpressEditPageSingleTariffBlock({ hashId }) {
  const [isLoading, setIsLoading] = useState(true);
  const [partnerSingleTariff, setPartnerSingleTariff] = useState(null);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [pendingData, setPendingData] = useState(null);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const getCurrentSingleTariff = async () => {
    try {
      setIsLoading(true);
      const response = await api.alloExpress.getPartnerSingleTariffWithHistory(hashId);
      if (response.status === 200) {
        setPartnerSingleTariff(response.data);
      }
    } finally {
      setIsLoading(false);
    }
  };
  const handleConfirmYes = async () => {
    const response = await api.alloExpress
      .createOrUpdatePartnerSingleTariff(hashId, pendingData);

    if (response.status === 200) {
      dispatch(setSuccessMessage(response.data.statusTitle, t('Успех')));
    } else {
      dispatch(setErrorMessage(response.data.statusTitle, t('Ошибка')));
    }
    setConfirmOpen(false);
    getCurrentSingleTariff();
  };

  useEffect(() => {
    getCurrentSingleTariff();
  }, []);
  const handleConfirmNo = () => {
    setConfirmOpen(false);
  };

  const [partnerHistoryModal, setPartnerHistoryModal] = useState(false);

  const tomorrow = (new Date()).setDate(new Date().getDate() + 1);
  const formattedDate = format(tomorrow, 'dd.MM.yyyy', { locale: uk });

  if (isLoading) {
    return (
      <>
        <Box sx={{ width: 'inherit' }}>
          <Box sx={{ mb: '10px' }}>
            <Typography color={'#303030'} fontWeight={700} fontSize={18}>Оплата послуги</Typography>
          </Box>
          <Paper>
            <Box sx={{
              width: '100%', height: '260px', display: 'flex', justifyContent: 'center',
            }}
            >
              <Loading isLoading={isLoading} />
            </Box>
          </Paper>
        </Box>
      </>
    );
  }

  return (
    <Box sx={{ width: 'inherit' }}>
      <Box sx={{ mb: '10px' }}>
        <Typography color={'#303030'} fontWeight={700} fontSize={18}>Оплата послуги</Typography>
      </Box>
      <Paper sx={{ padding: '20px 16px' }} elevation={2}>
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
        >
          <Box>
            <h4 className={'ma-0'}>Тариф за доставку</h4>
          </Box>
          <Box
            sx={{ display: 'flex', gap: 1 }}
          >
            <ButtonWithTooltip onClick={() => {
              setPartnerHistoryModal(true);
            }}
            >
              <LightHistoryIcon />
            </ButtonWithTooltip>
          </Box>
        </Box>
        <Box>
          <Divider sx={{ mb: '20px', mt: '10px' }} />
          <Box sx={{ mt: 2 }}>
            <FormControl
              component="fieldset"
              sx={{
                border: '1px solid #ccc',
                borderRadius: '8px',
                padding: '16px',
                position: 'relative',
              }}
            >
              <FormLabel
                component="legend"
                sx={{
                  position: 'absolute',
                  top: '-10px',
                  left: '12px',
                  backgroundColor: 'white',
                  px: 1,
                  fontSize: '14px',
                  color: 'gray',
                }}
              >
                <Typography color={'rgba(0, 0, 0, 0.6)'} fontSize={'14px'}>
                  Оберіть платника
                </Typography>
              </FormLabel>
              <RadioGroup
                value={partnerSingleTariff?.partnerTariffValue}
                onChange={(e) => {
                  const selectedValue = e.target.value;
                  setPendingData(selectedValue);
                  setConfirmOpen(true);
                }}
                sx={{
                  '& .MuiFormControlLabel-root': {
                    width: '300px',
                    pointerEvents: 'none',
                  },
                  '& .MuiRadio-root, & .MuiFormControlLabel-label': {
                    pointerEvents: 'auto',
                  },
                }}
                name="payer"
              >
                <FormControlLabel
                  sx={{
                    '& .MuiFormControlLabel-label': {
                      fontSize: '14px',
                    },
                  }}
                  value="buyer"
                  control={<Radio size={'small'} />}
                  label="Покупець"
                />
                <FormControlLabel
                  sx={{
                    '& .MuiFormControlLabel-label': {
                      fontSize: '14px',
                    },
                  }}
                  value="seller"
                  control={<Radio size={'small'} />}
                  label="Продавець"
                />
              </RadioGroup>
            </FormControl>
          </Box>
        </Box>
        <Box>
          <Divider sx={{ mb: '10px', mt: '20px' }} />
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'left',
            gap: 2,
          }}
          >
            <Box>
              <Typography
                color={'rgba(48, 48, 48, 0.72)'}
                fontSize={'12px'}
              >Поточний тариф:
              </Typography>
            </Box>
            <Box>
              <Typography fontSize={'12px'} fontWeight={700}>{partnerSingleTariff?.tariffValue} ₴</Typography>
            </Box>
          </Box>
        </Box>
      </Paper>
      <PartnerSingleTariffHistoryModal
        title={'Історія змін'}
        open={partnerHistoryModal}
        close={() => {
          setPartnerHistoryModal(false);
        }}
        items={partnerSingleTariff?.tariffHistory}
      />
      <ModalConfirm
        modalMaxWidth={'sm'}
        onConfirm={handleConfirmYes}
        showModal={confirmOpen}
        onCancel={handleConfirmNo}
        title={'Зміна платника за доставку перевізником "АЛЛО Express"'}
      >
        {pendingData === 'seller' && (
          <>
            <Typography variant="body2">
              <b>Увага!</b> Після збереження змін – оплачувати вартість доставки по всім замовленням «АЛЛО Express» буде <b>продавець</b>.
            </Typography>
            <Typography variant="body2" gutterBottom>
              Для покупця доставка замовлення буде безкоштовною.
            </Typography>
          </>
        )}
        {pendingData === 'buyer' && (
          <>
            <Typography variant="body2">
              <b>Увага!</b> Після збереження змін – оплачувати вартість доставки по всім замовленням «АЛЛО Express» буде <b>покупець</b>.
            </Typography>
            <Typography variant="body2" gutterBottom>
              Вартість доставки буде відображатись на сайті <Link target={'_blank'} href={'https://allo.ua'}>allo.ua</Link>, але оплата буде відбуватись при отриманні замовлення.
            </Typography>
          </>
        )}
        <Typography variant="body2">
          *Збережені дані будуть застосовані на сайті <Link target={'_blank'} href={'https://allo.ua'}>allo.ua</Link> з {formattedDate}
        </Typography>
      </ModalConfirm>
    </Box>
  );
}
