import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';

export function ButtonWithTooltip({
  onClick, tooltipText, children, disable,
}) {
  return (
    <Tooltip title={tooltipText || ''} placement="bottom-end">
      <Button
        disableRipple
        disable={disable}
        sx={{
          transition: 'transform 0.1s ease-in-out',
          '&:active': {
            transform: 'scale(0.8)',
          },
          '&:hover': {
            backgroundColor: 'inherit',
          },
          backgroundColor: 'inherit',
          color: 'inherit',
          p: 0,
        }}
        onClick={onClick}
      >
        {children}
      </Button>
    </Tooltip>
  );
}
