import { fromJS, Map } from 'immutable';
import { types } from './types';
import { pageSizes } from '../../config/globalConfig';
import { commonStateKeys } from '../../config/stateKeys';

const initialState = fromJS({
  currentPage: 0,
  filters: Map(),
  sorting: Map(),
  pageSize: pageSizes[0],
  historyPopupOpen: false,
  settingStatus: false,
  settingPending: false,
});

export const stateKeys = Object.freeze({
  controller: 'specialPrices',
  historyPopupOpen: 'historyPopupOpen',
});

export const specialPricesReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.SET_SPECIAL_PRICES_SORTING: {
      return state.set(commonStateKeys.sorting, Map(payload));
    }
    case types.SET_SPECIAL_PRICES_FILTERS: {
      return state.update(commonStateKeys.filters, filters => filters.merge(payload));
    }
    case types.RESET_SPECIAL_PRICES_FILTERS: {
      return state.set(commonStateKeys.sorting, Map())
        .set(commonStateKeys.filters, Map());
    }
    case types.SET_SPECIAL_PRICES_PAGE_SIZE: {
      return state.set(commonStateKeys.pageSize, payload);
    }
    case types.SET_SPECIAL_PRICES_CURRENT_PAGE: {
      return state.set(commonStateKeys.currentPage, payload);
    }
    case types.SET_SPECIAL_PRICES_HISTORY_POPUP_OPEN: {
      return state.set(stateKeys.historyPopupOpen, payload);
    }
    case types.SET_SPECIAL_PRICES_SETTING_STATUS: {
      return state.set(commonStateKeys.settingStatus, payload);
    }
    case types.SET_SPECIAL_PRICES_SETTING_STATUS_PENDING: {
      return state.set(commonStateKeys.settingPending, payload);
    }
    default:
      return state;
  }
};
