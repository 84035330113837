import Box from '@mui/material/Box';
import React from 'react';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import InputAdornment from '@mui/material/InputAdornment';
import RightSlideModal from '../../../containers/Modals/RightSlideModal/RightSlideModal';
import FormTextFieldInput from './input/FormTextFieldInput';
import NumberFormatCustom from '../../../components/NumberFormat/NumberFormatCustom';

const NumberFormat = (inputComponentProps) => {
  const { value, ...rest } = inputComponentProps;
  return (
    <NumberFormatCustom
      {...rest}
      maxLength={10}
      value={value.floatValue}
      decimalSeparator="."
      thousandSeparator=""
      decimalScale={2}
      fixedDecimalScale
      allowNegative={false}
      isAllowed={({ floatValue }) => floatValue === undefined || (Number.isInteger(floatValue) && floatValue >= 0)
      }
    />
  );
};

export function AdminSingleTariffFormModal({
  title, open, close, isValid, handleSubmit, onSubmit, singleTariffValue,
}) {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const currentTariff = useWatch({
    control,
    name: 'singleTariff',
  });
  const isUnchanged = currentTariff.formattedValue === String(singleTariffValue);
  const { t } = useTranslation();

  return (
    <RightSlideModal
      fullScreen
      margin={0}
      open={open}
      scroll="body"
      onClose={close}
      PaperProps={{
        style: {
          width: '360px',
          height: '100%',
          position: 'fixed',
          right: 0,
          top: 0,
          margin: 0,
          borderRadius: 0,
        },
      }}
    >
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
      >
        <Box
          borderBottom={'1px solid rgba(48, 48, 48, 0.12)'}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            pl: '20px', pr: '20px', pt: '14px', pb: '14px',
          }}
        >
          <Box>
            <Typography fontWeight={700} color={'#303030'} fontSize={18}>{title}</Typography>
          </Box>
          <IconButton
            sx={{ p: '0px' }}
            edge="start"
            color="inherit"
            onClick={close}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Box sx={{ p: '20px', flex: '1 1 auto' }}>
          <Controller
            name="singleTariff"
            control={control}
            rules={{
              required: t('Поле обов\'язкове'),
              maxLength: 6,
              pattern: {
                value: /^(0|[1-9]\d*)$/,
                message: t('Будь ласка, введіть ціле позитивне число'),
              },
            }}
            render={({ field: { onChange, name, value } }) => (
              <FormTextFieldInput
                input={'text'}
                variant="outlined"
                label={'Тариф'}
                fullWidth
                name={name}
                onChange={onChange}
                error={!!errors.singleTariff}
                helperText={errors.singleTariff?.message}
                placeholder={'Вкажіть ціле позитивне число'}
                required
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  inputComponent: NumberFormat,
                  value,
                  maxLength: 10,
                  endAdornment: <InputAdornment position="end">₴</InputAdornment>,
                }}
              />
            )}
          />
        </Box>
        <Box
          sx={{
            p: '20px',
            borderTop: '1px solid rgba(48, 48, 48, 0.12)',
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
            <Button sx={{ marginRight: '8px' }} variant="outlined" onClick={close}>Скасувати</Button>
            <Button
              disabled={currentTariff.value === '' || !isValid || isUnchanged}
              variant="contained"
              onClick={handleSubmit(onSubmit)}
            >Підтвердити
            </Button>
          </Box>
        </Box>
      </Box>
    </RightSlideModal>
  );
}
