import { getActionCreator } from '../../../_helpers/getActionCreator';
import { types } from './types';

export const actions = {
  setCurrentPage(currentPage) {
    return getActionCreator(types.SET_SPECIAL_PRICES_CURRENT_PAGE, currentPage);
  },
  setFilters(filters) {
    return getActionCreator(types.SET_SPECIAL_PRICES_FILTERS, filters);
  },
  resetFilters() {
    return getActionCreator(types.RESET_SPECIAL_PRICES_FILTERS);
  },
  setPageSize(pageSize) {
    return getActionCreator(types.SET_SPECIAL_PRICES_PAGE_SIZE, pageSize);
  },
  setSorting(sorting) {
    return getActionCreator(types.SET_SPECIAL_PRICES_SORTING, sorting);
  },
  setSettingStatus(settingsStatus) {
    return getActionCreator(types.SET_SPECIAL_PRICES_SETTING_STATUS, settingsStatus);
  },
  setSettingsPending(settingPending) {
    return getActionCreator(types.SET_SPECIAL_PRICES_SETTING_STATUS_PENDING, settingPending);
  },
  setHistoryPopupOpen(payload) {
    return getActionCreator(types.SET_SPECIAL_PRICES_HISTORY_POPUP_OPEN, payload);
  },
};
