
export const types = {
  SET_SPECIAL_PRICES_CURRENT_PAGE: 'SET_SPECIAL_PRICES_CURRENT_PAGE',
  SET_SPECIAL_PRICES_FILTERS: 'SET_SPECIAL_PRICES_FILTERS',
  SET_SPECIAL_PRICES_PAGE_SIZE: 'SET_SPECIAL_PRICES_PAGE_SIZE',
  SET_SPECIAL_PRICES_SORTING: 'SET_SPECIAL_PRICES_SORTING',
  SET_SPECIAL_PRICES_HISTORY_LIST: 'SET_SPECIAL_PRICES_LIST',
  SET_SPECIAL_PRICES_HISTORY_CURRENT_PAGE: 'SET_SPECIAL_PRICES_CURRENT_PAGE',
  SET_SPECIAL_PRICES_HISTORY_FILTERS: 'SET_SPECIAL_PRICES_FILTERS',
  SET_SPECIAL_PRICES_HISTORY_PAGE_SIZE: 'SET_SPECIAL_PRICES_PAGE_SIZE',
  SET_SPECIAL_PRICES_HISTORY_SORTING: 'SET_SPECIAL_PRICES_SORTING',
  RESET_SPECIAL_PRICES_FILTERS: 'RESET_SPECIAL_PRICES_FILTERS',
  SET_SPECIAL_PRICES_HISTORY_POPUP_OPEN: 'SET_SPECIAL_PRICES_HISTORY_POPUP_OPEN',
  SET_SPECIAL_PRICES_SETTING_STATUS: 'SET_SPECIAL_PRICES_SETTING_STATUS',
  SET_SPECIAL_PRICES_SETTING_STATUS_PENDING: 'SET_SPECIAL_PRICES_SETTING_STATUS_PENDING',
};
