import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ButtonWithTooltip } from '../../../components/Buttons/ButtonWithIcon/Light/ButtonWithTooltip';
import { HistoryIcon } from '../../../icons/HistoryIcon';
import { EditIcon } from '../../../icons/EditIcon';
import { PartnerAlloExpressHistoryModal } from './PartnerAlloExpressRequestHistoryModal';
import { getStatusColor } from '../../../../engine/config/serviceRequestsStatuses';
import { FileStatusIcon } from '../../../icons/FileStatusIcon';
import { pageLinks } from '../../../../engine/config/routes';

export function PartnerAlloExpressRequestBlock({ userPartnerHashId, partnerServiceRequest }) {
  const [historyModal, setHistoryModal] = useState(false);
  const navigate = useNavigate();
  const editLink = pageLinks.shippingIntegration.edit(userPartnerHashId);

  return (
    <Paper sx={{ padding: '20px 16px', height: '317px' }} elevation={2}>
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
      >
        <Box>
          <h4 className={'ma-0'}>Заявка на послугу доставки Алло Express</h4>
        </Box>
        <Box
          sx={{ display: 'flex', gap: 1 }}
        >
          <ButtonWithTooltip
            tooltipText={'Статус послуги'}
            onClick={() => {
              navigate(`${editLink}?tab=editStatus`);
            }}
          >
            <FileStatusIcon />
          </ButtonWithTooltip>
          <ButtonWithTooltip
            tooltipText={'Історія змін'}
            onClick={() => {
              setHistoryModal(true);
            }}
          >
            <HistoryIcon />
          </ButtonWithTooltip>
          <ButtonWithTooltip
            tooltipText={'Редагування заявки'}
            onClick={() => {
              navigate(`${editLink}?tab=editRequests`);
            }}
          >
            <EditIcon />
          </ButtonWithTooltip>
        </Box>
      </Box>
      <Box>
        <Divider sx={{ mb: '20px', mt: '10px' }} />
        <Grid container spacing={1}>
          <Grid item md={4} lg={3} xs={6} sm={4}>
            <Box>
              <Typography
                color={'rgba(48, 48, 48, 0.72)'}
                fontSize={'12px'}
              >Статус:
              </Typography>
            </Box>
          </Grid>
          <Grid item md={8} lg={9} xs={6} sm={8}>
            <Box>
              <Typography fontSize={'14px'} color={getStatusColor(partnerServiceRequest.statusValue)}>{partnerServiceRequest.statusLabel}</Typography>
            </Box>
          </Grid>
          <Grid item md={4} lg={3} xs={6} sm={4}>
            <Box>
              <Typography color={'rgba(48, 48, 48, 0.72)'} fontSize={'12px'}>Назва магазину:</Typography>
            </Box>
          </Grid>
          <Grid item md={8} lg={9} xs={6} sm={8}>
            <Box>
              <Typography fontSize={'14px'}>{partnerServiceRequest.partnerCompanyName}</Typography>
            </Box>
          </Grid>
          <Grid item md={4} lg={3} xs={6} sm={4}>
            <Box>
              <Typography color={'rgba(48, 48, 48, 0.72)'} fontSize={'12px'}>ID магазину:</Typography>
            </Box>
          </Grid>
          <Grid item md={8} lg={9} xs={6} sm={8}>
            <Box>
              <Typography fontSize={'14px'}>{partnerServiceRequest.partnerMId}</Typography>
            </Box>
          </Grid>
          <Grid item md={4} lg={3} xs={6} sm={4}>
            <Box>
              <Typography color={'rgba(48, 48, 48, 0.72)'} fontSize={'12px'}>Назва підприємства:</Typography>
            </Box>
          </Grid>
          <Grid item md={8} lg={9} xs={6} sm={8}>
            <Box>
              <Typography fontSize={'14px'}>{partnerServiceRequest.enterpriseCompanyName}</Typography>
            </Box>
          </Grid>
          <Grid item md={4} lg={3} xs={6} sm={4}>
            <Box>
              <Typography color={'rgba(48, 48, 48, 0.72)'} fontSize={'12px'}>Дата створення:</Typography>
            </Box>
          </Grid>
          <Grid item md={8} lg={9} xs={6} sm={8}>
            <Box>
              <Typography fontSize={'14px'}>{partnerServiceRequest.createdAt}</Typography>
            </Box>
          </Grid>
          <Grid item md={4} lg={3} xs={6} sm={4}>
            <Box>
              <Typography color={'rgba(48, 48, 48, 0.72)'} fontSize={'12px'}>Дата оновлення:</Typography>
            </Box>
          </Grid>
          <Grid item md={8} lg={9} xs={6} sm={8}>
            <Box>
              <Typography fontSize={'14px'}>{partnerServiceRequest.updatedAt}</Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <PartnerAlloExpressHistoryModal
        title={'Історія змін'}
        open={historyModal}
        partnerServiceRequestId={partnerServiceRequest.id}
        partnerHash={userPartnerHashId}
        close={() => {
          setHistoryModal(false);
        }}
        items={[]}
      />
    </Paper>
  );
}
