import FeedbackOutlinedIcon from '@mui/icons-material/FeedbackOutlined';
import { useTranslation } from 'react-i18next';
import NotificationText from '../../../../../../components/Notifications/NotificationText';
import { COLOR } from '../../../../../../containers/App/AppStyles';

const WarningNotificationText = ({
  title = 'Обратите внимание!',
  titleColor = COLOR.darkStyle.brand.main,
  border = `1px solid ${COLOR.darkStyle.brand.main}`,
  backgroundColor = 'rgba(249, 121, 136, 0.3)',
  textColor = COLOR.darkStyle.fontColorMain,
  text,
}) => {
  const { t } = useTranslation();

  return (
    <NotificationText
      icon={<FeedbackOutlinedIcon sx={{ color: COLOR.darkStyle.brand.main }} />}
      title={t(title)}
      titleColor={titleColor}
      border={border}
      backgroundColor={backgroundColor}
      boxShadow="none"
      textColor={textColor}
    >
      {t(text)}
    </NotificationText>
  );
};

export default WarningNotificationText;
