
/**
 * provide common state keys for reducers and selectors
 *
 * NO need to create a new one every reduces slice
 */
export const commonStateKeys = {
  currentPage: 'currentPage',
  filters: 'filters',
  items: 'items',
  pending: 'pending',
  sorting: 'sorting',
  totalCount: 'totalCount',
  pageSize: 'pageSize',
  selection: 'selection',
  settingStatus: 'settingStatus',
  settingPending: 'settingPending',
};
