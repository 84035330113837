export const userRoles = Object.freeze({
  admin: 'ROLE_ADMIN',
  manager: 'ROLE_MANAGER',
  partner: 'ROLE_PARTNER',
  partnerManageProfile: 'ROLE_PARTNER_MANAGE_PROFILE',
  partnerActive: 'ROLE_PARTNER_ACTIVE',
  partnerSigningContract: 'ROLE_PARTNER_SIGNING_CONTRACT',
  partnerRating: 'ROLE_PARTNER_RATING',
  partnerContentManager: 'ROLE_PARTNER_CONTENT_MANAGER',
  partnerBilling: 'ROLE_PARTNER_BILLING',
  contentManager: 'ROLE_CONTENT_MANAGER',
  creditDepartmentManager: 'ROLE_MANAGER_CREDIT_DEPARTMENT',
  salesManager: 'ROLE_SALES_MANAGER',
  callCenter: 'ROLE_CALL_CENTER',
  partnerPaymentWaiting: 'ROLE_PARTNER_PAYMENT_WAITING',
  partnerDisabled: 'ROLE_PARTNER_DISABLED',
  partnerOrderManager: 'ROLE_PARTNER_ORDER_MANAGER',
  partnerCanceled: 'ROLE_PARTNER_CANCELLED',
});
