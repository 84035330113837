import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import PageHeader from '../../components/PageHeader/PageHeader';
import { PartnerSingleTariffEditBlock } from './components/PartnerSingleTariffEditBlock';
import { api } from '../../../engine/config/api';
import { selectors } from '../../../engine/config/selectors';
import Loading from '../../components/Loading/Loading';
import { PartnerAlloExpressRequestBlock } from './components/PartnerAlloExpressRequestBlock';
import { setErrorMessage } from '../../../engine/core/ui/saga/asyncActionNotificationMessages';

export function AlloExpressMainPage() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userPartnerHashId = useSelector(selectors.user.userPartnerHashId);
  const [pending, setPending] = useState(true);
  const [partnerServiceRequest, setPartnerServiceRequest] = useState({});
  const fetchData = async () => {
    try {
      setPending(true);
      const response = await api.partnerServiceRequests.getPartnerServiceRequest(userPartnerHashId);
      if (response.status === 200) {
        setPartnerServiceRequest(response.data);
      } else {
        dispatch(setErrorMessage(response.data.status, t('Ошибка')));
      }
    } finally {
      setPending(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (pending) {
    return (
      <Box sx={{
        width: '100%', height: '100%', display: 'flex', justifyContent: 'center',
      }}
      >
        <Loading isLoading={pending} />
      </Box>
    );
  }

  return (
    <>
      <PageHeader title={t('Алло Express')} />
      <Grid spacing={'8px'} container>
        <Grid item lg={6} md={12} sm={12} xs={12}>
          <PartnerAlloExpressRequestBlock
            userPartnerHashId={userPartnerHashId}
            partnerServiceRequest={partnerServiceRequest}
          />
        </Grid>
        <Grid item lg={6} md={12} sm={12} xs={12} />
        <Grid item lg={6} md={12} sm={12} xs={12}>
          <PartnerSingleTariffEditBlock />
        </Grid>
      </Grid>
    </>
  );
}
