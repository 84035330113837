import { emphasize } from '@mui/material/styles';
import indigo from '@mui/material/colors/indigo';
import green from '@mui/material/colors/green';
import red from '@mui/material/colors/red';
import { COLOR, COMPONENTS_TYPES, Z_INDEX } from '../../../../containers/App/AppStyles';
import { useEmotionStyles } from '../../../../hooks/useEmotionStyles';

export const styles = {
  menuPortal: baseStyles => ({ ...baseStyles, zIndex: Z_INDEX.selectMenu }),
  menuList: (baseStyles, state) => {
    const { selectProps, hasSearch } = state;
    const isDarkStyle = selectProps?.textFieldProps.color === COMPONENTS_TYPES.SELECT.dark;
    const maxHeightAndHasSearch = selectProps?.hasSearch || hasSearch
      ? baseStyles.maxHeight - 70
      : baseStyles.maxHeight;
    const maxHeight = selectProps?.hasConfirmButton
      ? maxHeightAndHasSearch - 50
      : maxHeightAndHasSearch;
    return ({
      ...baseStyles,
      maxHeight,
      padding: '0',
      ...(isDarkStyle && {
        '&::-webkit-scrollbar-thumb': {
          border: '2px solid #303030',
          background: 'rgba(255, 255, 255, 0.54)',
        },
        '&:hover::-webkit-scrollbar-thumb': {
          borderWidth: 0,
          background: 'rgba(255, 255, 255, 0.34)',
        },
      }),
    });
  },
  input: baseStyles => (
    {
      ...baseStyles,
      padding: '0px 0px !important',
      overflow: 'hidden',
    }
  ),
  menu: (baseStyles, state) => {
    const { selectProps } = state;
    const minMenuWidth = selectProps?.minMenuWidth;
    const isDarkStyle = selectProps?.textFieldProps.color === COMPONENTS_TYPES.SELECT.dark;
    return ({
      ...baseStyles,
      zIndex: Z_INDEX.selectMenu,
      marginTop: '4px',
      boxShadow: '0px 0px 12px 0px rgba(0, 0, 0, 0.12)',
      borderRadius: '5px',
      overflow: 'hidden',
      minWidth: minMenuWidth ? `${minMenuWidth}px` : '75px',
      ...(isDarkStyle && {
        backgroundColor: COLOR.grey.main,
        filter: 'drop-shadow(0px 0px 12px rgba(82, 133, 204, 0.2))',
        border: '1px solid rgba(255, 255, 255, 0.08)',
        '& *::-webkit-scrollbar-thumb': {
          border: '2px solid #303030',
          background: 'rgba(255, 255, 255, 0.54)',
        },
        '& *:hover::-webkit-scrollbar-thumb': {
          borderWidth: 0,
          background: 'rgba(255, 255, 255, 0.34)',
        },
        '& .MuiOutlinedInput-notchedOutline': {
          borderWidth: '1px !important',
        },
        '.MuiPaper-root': {
          backgroundColor: COLOR.grey.main,
        },
        '.select-tags__menu-list > div': {
          paddingLeft: '8px',
          '& p': {
            color: COLOR.darkStyle.fontColorMain,
          },
        },
        '.select-tags__menu > div': {
          '& p': {
            color: 'red',
          },
        },
        '.MuiMenuItem-root': {
          paddingLeft: '8px',
          color: COLOR.darkStyle.fontColorMain,
          '&:hover': {
            backgroundColor: COLOR.darkStyle.white['8'],
          },
        },
        input: {
          '&:-webkit-autofill': {
            WebkitBoxShadow: `0 0 0 1000px ${COLOR.darkStyle.main} inset`,
            WebkitTextFillColor: COLOR.darkStyle.fontColorMain,
            borderRadius: '0',
            color: '#fff !important',
          },
        },
      }),
    });
  },
  control: baseStyles => ({
    ...baseStyles,
    borderColor: 'hsl(0,0%,80%) !important',
    boxShadow: 'none !important',
    cursor: 'pointer',
    lineHeight: 1,
  }),
  option: (baseStyles, { isDisabled, isSelected, isFocused }) => ({
    ...baseStyles,
    color: 'rgba(0, 0, 0, 0.87)',
    backgroundColor: isSelected || isFocused ? 'rgba(198, 38, 40, 0.08)' : undefined,
    cursor: 'pointer',
    ':active': {
      ...baseStyles[':active'],
      backgroundColor: !isDisabled
        ? 'rgba(198, 38, 40, 0.08)'
        : undefined,
    },
  }),
  singleValue: ({ position, ...baseStyles }) => ({
    ...baseStyles,
    marginLeft: 0,
    maxWidth: 'initial',
    position: 'relative',
    fontWeight: 'initial',
    top: 0,
    maxHeight: 30,
    whiteSpace: 'nowrap !important',
    transform: 'initial',
  }),
  multiValueRemove: () => ({
    display: 'flex',
    padding: '4px',
    borderLeft: '1px solid #0000001f',
    marginLeft: '2px',
    cursor: 'pointer',
  }),
  valueContainer: (baseStyles, state) => (
    {
      padding: '0px !important',
      '& input': {
        ...state.selectProps.textFieldProps.isCategorySelect ? {
          padding: '0px !important',
          width: '325px !important',
        } : {
          position: 'absolute !important',
          left: 10,
          top: 0,
          fontSize: '20px',
        },
      },
      ...state.isMulti
        ? {
          display: 'block',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        }
        : baseStyles,
    }),
  dropdownIndicator: (provided, state) => {
    const menuIsOpen = state?.selectProps?.menuIsOpen;
    const tableColor = menuIsOpen ? COLOR.grey['72'] : COLOR.grey['38'];
    const classicColor = menuIsOpen ? COLOR.controls.dark : COLOR.grey['54'];
    return ({
      ...provided,
      transform: menuIsOpen ? 'rotate(180deg)' : 'none',
      color: state?.isClassic ? classicColor : tableColor,
      fontSize: state?.isClassic ? '10px' : 'inherit',
      marginRight: state?.isClassic ? '14px' : 'inherit',
      padding: '2px',
      '&:hover': {
        color: COLOR.grey['72'],
      },
    });
  },
};

export const SelectStyle = props => useEmotionStyles(theme => ({
  borderBottom: { borderBottom: '1px solid #000' },
  marginNormal: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  valueContainer: {
    display: 'block',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '&.select-tags__value-container': {
      overflow: 'hidden',
      position: 'relative',
      maxHeight: '40px',
      padding: '2px 5px 2px 13px',
      minHeight: '28px',
    },
    '& > input': {
      position: 'absolute !important',
      left: 0,
      top: 0,
      fontSize: '20px',
    },
    '& > div:not([role="button"])': {
      position: 'absolute',
      whiteSpace: 'nowrap!important',
      fontWeight: 400,
      fontSize: 13,
      opacity: 0,
    },
    '& div[role="button"] span': {
      maxWidth: '100%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: 'block',
      fontWeight: 400,
    },
  },
  valueContainerMulti: {
    '& input': {
      position: 'absolute !important',
      left: 0,
      top: 0,
      fontSize: '20px',
    },
  },
  valueContainerSingle: {
    '&.select-tags__value-container': {
      maxHeight: '40px',
      padding: '2px 5px 2px 13px',
      minHeight: '28px',
      '& .select-tags__single-value': {
        color: `${COLOR.grey.main}`,
        fontSize: '14px',
      },
    },
  },
  valueTreeContainer: {
    overflow: 'hidden',
    position: 'relative',
    lineHeight: 1,
    paddingLeft: '9px',
    color: `${COLOR.grey['38']}`,
    '& > input': {
      position: 'absolute !important',
      left: 0,
      top: 0,
      fontSize: '20px',
    },
    '& > div:not([role="button"])': {
      position: 'absolute',
      whiteSpace: 'nowrap!important',
      fontWeight: 400,
      fontSize: 13,
      opacity: 0,
    },
    '& div[role="button"] span': {
      maxWidth: '100%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: 'block',
      fontWeight: 400,
    },
  },
  valueTreeLabel: {
    overflow: 'hidden',
    maxWidth: '100%',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    height: '28px',
    margin: 0,
    lineHeight: '28px',
    color: `${COLOR.grey.main}`,
    '& .tree-placeholder': {
      color: `${COLOR.grey['38']}`,
      userSelect: 'none',
    },
    '& input': {
      position: 'absolute',
    },
  },
  valueTreeLabelOutlined: {
    width: '100%',
    height: '100%',
    '& span': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  },
  chip: {
    display: 'inline-flex',
    margin: '0 3px',
    height: 28,
    maxWidth: 'calc(100% - 4px)',
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.mode === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
      0.08,
    ),
  },
  noOptionsMessage: {
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    position: 'absolute',
    left: '15px',
    right: 0,
    fontSize: 14,
    lineHeight: '28px',
    color: COLOR.grey['38'],
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    paddingRight: '5px',
    whiteSpace: 'nowrap',
  },
  paper: {
    paddingTop: props.isClassic ? '8px' : '6px',
    paddingBottom: props.isClassic ? '8px' : '6px',
    marginTop: props.isClassic ? '-4px' : undefined,
    borderRadius: props.isClassic ? '8px' : undefined,
    boxShadow: props.isClassic ? '0px 3px 5px rgba(32, 33, 36, 0.2)' : 'none',
  },
  menuItem: {
    padding: props.isClassic ? '0 8px' : '0 10px',
    margin: 0,
    color: COLOR.grey.main,
    boxShadow: 'none',
    '& .MuiMenuItem-root': {
      padding: props.isClassic ? '8px' : '5px 11px',
      borderRadius: '5px',
      minHeight: '40px',
      fontSize: '14px',
      '&.Mui-selected, &.is-focused, &:hover': {
        background: props.color === 'dark' ? 'rgba(255,255,255, .08)' : COLOR.grey['4'],
      },
    },
  },
  longRowItem: {
    '& $checkBoxRoot': {
      margin: '0 8px 0 0',
      '& .MuiSvgIcon-root': {
        width: '26px',
        height: '26px',
      },
    },
  },
  divider: {
    height: theme.spacing(2),
  },
  wrapper: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  wrapperSelect: {
    position: 'relative',
    flexGrow: 1,
    maxWidth: '100%',
    ...(props.color === 'dark' && {
      '& *::-webkit-scrollbar-thumb': {
        border: '2px solid #303030',
        background: 'rgba(255, 255, 255, 0.54)',
      },
      '& *:hover::-webkit-scrollbar-thumb': {
        borderWidth: 0,
        background: 'rgba(255, 255, 255, 0.34)',
      },
    }),
    '& .select-tags__indicator-separator': {
      display: props.isClearable ? 'initial' : 'none',
      height: '20px',
      marginRight: '7px',
      backgroundColor: 'rgba(48, 48, 48, 0.12)',
    },
    '& .select-tags__clear-indicator': {
      fontSize: '11px',
      color: props.menuIsOpen ? COLOR.controls.dark : COLOR.grey['54'],
      '&:hover': {
        color: COLOR.grey['72'],
      },
    },
    '& .MuiInputBase-root': {
      cursor: 'pointer',
      minHeight: '32px',
    },
    '& .MuiInputBase-root:after': {
      borderColor: COLOR.controls.dark,
    },
    '& .MuiInputBase-root.MuiInput-root:before': {
      display: 'none',
    },
  },
  wrapperSelectClassic: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: props.color === 'dark' ? COLOR.darkStyle.white[54] : COLOR.grey['38'],
      borderWidth: '1px !important',
    },
    '& .select-tags__single-value': {
      borderColor: COLOR.grey.main,
    },
    '& .Mui-error': {
      '& .select-tags__indicator': {
        color: COLOR.error,
      },
    },
    '& .Mui-error.Mui-focused': {
      '& .select-tags__indicator': {
        color: COLOR.controls.dark,
      },
      '&.MuiFormLabel-root': {
        color: COLOR.controls.dark,
      },
    },
    '& .Mui-focused': {
      cursor: 'text',
      '& .select-tags__indicator': {
        color: COLOR.controls.dark,
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: COLOR.controls.dark,
      },
    },
    '&:hover .MuiFormLabel-root:not(.Mui-error, .Mui-focused)': {
      color: (props.disabled || props.color === 'dark') ? undefined : COLOR.grey.main,
    },
    '& .MuiOutlinedInput-root:not(.Mui-error, .Mui-focused)': {
      '&:hover fieldset': {
        borderColor: COLOR.grey.main,
      },
      '&:hover .select-tags__indicator': {
        color: COLOR.grey.main,
      },
    },
  },
  changedFormControl: {
    '& .MuiInput-underline:after': {
      transform: 'scaleX(1)',
      borderBottom: `2px solid ${COLOR.changeColor}`,
    },
    '& label': {
      color: COLOR.changeColor,
    },
    '& .MuiButton-outlined': {
      border: `1px solid ${COLOR.changeColor}`,
    },
    '& fieldset, fieldset.MuiOutlinedInput-notchedOutline': {
      borderColor: COLOR.changeColor,
    },
  },
  wrapperSelectDark: {
    '& .MuiFormLabel-root': {
      color: COLOR.darkStyle.white['54'],
    },
    '& .select-tags__single-value': {
      color: '#fff',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: COLOR.darkStyle.white['54'],
    },
    '& .Mui-disabled': {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: COLOR.darkStyle.white['38'],
        color: COLOR.darkStyle.white['38'],
      },
    },
    '& .select-tags__indicator': {
      color: COLOR.darkStyle.white['54'],
    },
    '&:hover .MuiFormLabel-root:not(.Mui-error, .Mui-focused)': {
      color: COLOR.darkStyle.white['72'],
    },
    '& .MuiOutlinedInput-root:not(.Mui-error, .Mui-focused)': {
      '&:hover fieldset': {
        borderColor: COLOR.darkStyle.white['72'],
      },
      '&:hover .select-tags__indicator': {
        color: COLOR.darkStyle.white['72'],
      },
    },
  },
  loader: {
    transform: 'scale(0.6)',
    position: 'absolute',
    top: 0,
    bottom: 0,
  },
  wrapperButton: {
    display: 'flex',
    alignItems: 'flex-end',
    marginBottom: -4,
  },
  button: {
    padding: 7,
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: 'transparent',
  },
  compareButton: {
    color: indigo[500],
  },
  compareButtonActive: {
    borderColor: indigo[500],
  },
  checkButton: {
    color: green[500],
  },
  checkButtonActive: {
    borderColor: green[500],
  },
  cancelButton: {
    color: red[500],
  },
  cancelButtonActive: {
    borderColor: red[500],
  },
  withConfirmButtonContainer: {
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
    padding: '8px 8px 2px',
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'flex-start',
  },
  rejectButton: {
    paddingTop: '8px',
    paddingBottom: '8px',
    marginRight: '8px',
    borderColor: COLOR.grey['24'],
    '&:hover': {
      borderColor: COLOR.grey['24'],
    },
  },
  checkBoxRoot: {
    padding: 0,
    margin: '0 8px 0 0',
  },
  menuItemRoot: {
    borderRadius: '5px',
    paddingLeft: 4,
    whiteSpace: 'normal',
  },
  menuTreeItem: {
    padding: '0 10px',
    color: COLOR.grey.main,
    boxShadow: 'none',
  },
  treeItemMui: {
    borderRadius: '4px',
    display: 'block',
    '& .MuiTreeItem-content': {
      alignItems: 'flex-start',
      borderRadius: '4px',
      padding: '0 0 0 10px',
      minWidth: '100%',
      '&.Mui-selected, &.is-focused, &:hover': {
        background: COLOR.grey['4'],
      },
    },
    '& .MuiTreeItem-label': {
      display: 'block',
      padding: 0,
    },
    '& .MuiTreeItem-iconContainer': {
      width: '10px',
      marginRight: '6px',
      marginTop: '16px',
      color: COLOR.grey['72'],
      '& svg': {
        fontSize: '10px',
      },
    },
  },
  treeViewMui: {
    padding: 0,
  },
  treeMenuItem: {
    display: 'flex',
    alignItems: 'flex-start',
    padding: '8px 10px 8px 0',
    cursor: 'pointer',
    whiteSpace: 'normal',
    '&:hover': {
      background: 'transparent',
    },
  },
  treeCheckbox: {
    padding: 0,
    margin: '0 8px 0 0',
    display: 'inline-flex',
    '& .MuiSvgIcon-root': {
      width: '26px',
      height: '26px',
    },
  },
  menuHolder: {
    padding: '10px',
  },
  searchHolder: {
    position: 'relative',
    '& .MuiInputBase-input': {
      padding: '7px 10px 7px 13px',
      height: '18px',
      lineHeight: '18px',
      fontSize: '14px',
      color: props.color === 'dark'
        ? COLOR.darkStyle.fontColorMain
        : COLOR.grey.main,
      caretColor: `${COLOR.controls.dark}`,
    },
    '& .MuiInputLabel-root': {
      fontSize: '14px',
      lineHeight: '14px',
      transform: 'translate(36px, 11px) scale(1)',
      color: `${COLOR.grey['54']}`,

      '&.Mui-error:not(.Mui-focused)': {
        color: `${COLOR.controls.dark}`,
        '& + .MuiInputBase-root .MuiOutlinedInput-notchedOutline': {
          borderColor: `${COLOR.error}`,
        },
      },
      '&.Mui-error.Mui-focused': {
        color: `${COLOR.controls.dark}`,
        '& + .MuiInputBase-root .MuiOutlinedInput-notchedOutline': {
          borderColor: `${COLOR.controls.dark}`,
        },
      },
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: `1px solid ${COLOR.grey['24']}`,
      paddingLeft: '13px',
      top: '-4px',
    },
    '& .MuiInputBase-root': {
      borderRadius: '5px',
      '&.Mui-focused': {
        '& .MuiOutlinedInput-notchedOutline': {
          border: `2px solid ${COLOR.controls.dark}`,
        },
      },
    },
  },
  searchIcon: {
    position: 'absolute',
    left: '9px',
    top: '10px',
    width: '16px',
    height: '16px',
    color: props.color === 'dark'
      ? COLOR.darkStyle.fontColorMain
      : COLOR.grey['72'],
  },
  searchClearIcon: {
    position: 'absolute',
    right: '9px',
    top: '10px',
    width: '16px',
    height: '16px',
    color: `${COLOR.grey['72']}`,
    cursor: 'pointer',
  },
  searchIconHide: {
    display: 'none',
  },
  search: {
    margin: 0,
  },
  searchDark: {
    '&': {
      color: COLOR.darkStyle.white['38'],
      '& .MuiInputAdornment-positionEnd p': {
        color: COLOR.darkStyle.white['72'],
      },
      '& .MuiInputLabel-root': {
        color: COLOR.darkStyle.white['72'],
        '&.Mui-focused': {
          color: COLOR.darkStyle.brand.secondary,
        },
      },
      '&:hover': {
        '&.Mui-focused': {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: COLOR.darkStyle.brand.secondary,
            color: COLOR.darkStyle.brand.secondary,
          },
        },
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: `${COLOR.darkStyle.white['54']}`,
        },
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: COLOR.darkStyle.white['24'],
      },
      '& .MuiInputBase-root': {
        color: COLOR.darkStyle.fontColorMain,
        input: {
          '&:-webkit-autofill': {
            WebkitBoxShadow: `0 0 0 1000px ${COLOR.darkStyle.main} inset`,
            WebkitTextFillColor: COLOR.darkStyle.fontColorMain,
            borderRadius: '0',
            color: '#fff !important',
          },
        },
        '&:hover': {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: `${COLOR.darkStyle.white['54']}`,
          },
        },
        '&.Mui-focused': {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: COLOR.darkStyle.brand.secondary,
            color: COLOR.darkStyle.brand.secondary,
          },
        },
        '& .MuiInputAdornment-root': {
          color: COLOR.darkStyle.white['54'],
        },
      },
    },
  },
  searchNotEmpty: {
    '& .MuiInputLabel-root': {
      fontSize: '12px',
      transform: 'translate(19px, -6px) scale(1)',
      color: `${COLOR.controls.dark}`,
    },
  },
  errorColor: {
    color: red[500],
  },
  formControl: {
    width: '100%',
  },
  wrapperCategoryTreeForm: {
    display: 'flex',
  },
}));
