import Box from '@mui/material/Box';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SaveIcon from '@mui/icons-material/Save';
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router';
import Select from '../../../../../../components/_Form/Selects/Select/Select';
import WarningNotificationText from './WarningNotificationText';
import { selectors } from '../../../../../../../engine/config/selectors';
import ButtonWithIconAndCircularProgress from '../../../../../../components/Buttons/ButtonWithIconAndCircularProgress';
import { BUTTON_SIZE } from '../../../../../../containers/App/AppStyles';
import { api } from '../../../../../../../engine/config/api';
import { setErrorMessage, setSuccessMessage } from '../../../../../../../engine/core/ui/saga/asyncActionNotificationMessages';
import TextField from '../../../../../../components/_Form/TextField/TextField/TextField';
import { generateInput, generateMeta } from '../../../../Grouping/Add/utils/generateInput';
import { asyncActions } from '../../../../../../../engine/core/contentProduct/saga/asyncActions';
import { useQueryParams } from '../../../../../../hooks/useQueryParams';
import { convertSortingObjectToUrlParams } from '../../../../../../../_helpers/convertDataToUrlParams';

const apiRequest = (val) => {
  switch (val) {
    case 'create': {
      return api.torps.createTORP;
    }
    case 'update': {
      return api.torps.updateTORPfromGrid;
    }
    case 'delete': {
      return api.torps.deleteProductFromTORP;
    }
    default: {
      return null;
    }
  }
};


const TORPsButtonGroups = () => {
  const { t } = useTranslation();
  const selection = useSelector(selectors.contentProduct.selection);
  const filters = useSelector(selectors.contentProduct.filters);
  const sorting = useSelector(selectors.contentProduct.sorting);
  const limit = useSelector(selectors.contentProduct.pageSize);
  const currentPage = useSelector(selectors.contentProduct.currentPage);
  const offset = limit * currentPage;
  const dispatch = useDispatch();
  const { categoryId } = useParams();
  const attributeSetId = useQueryParams('attributeSetId');

  const hasFilter = filters.size > 0;


  const options = [
    { label: hasFilter ? 'Створити  ТОРП з фільтрованого' : 'Створити ТОРП', value: 'create' },
    { label: hasFilter ? 'Додавання у  ТОРП  фільтрованого' : 'Додати в ТОРП', value: 'update' },
    { label: 'Видалити з ТОРП', value: 'delete' },
  ];

  const [selectedValue, setSelectedValue] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const handleChange = (value) => {
    setSelectedValue(value);
  };

  const {
    handleSubmit, control, watch, formState: { errors, touchedFields },
  } = useForm({
    mode: 'all',
    defaultValues: { TORPsku: '' },
  });

  const skuTORP = watch('TORPsku');

  useEffect(() => {
    if (selection.size === 0 && filters.size === 0) {
      setSelectedValue(null);
    }
  }, [selection, filters]);


  const onSubmit = async () => {
    setIsLoading(true);
    const productFilter = filters.toJS().reduce((acc, current) => {
      if (Array.isArray(current.value)) {
        acc[current.columnName] = current.value.map(val => val.value).join();
      } else {
        acc[current.columnName] = current.value;
      }
      return acc;
    }, {});

    const apiPath = apiRequest(selectedValue.value);
    const productIds = selection.size > 0 ? selection.toArray() : undefined;
    const sku = skuTORP || undefined;
    const response = await apiPath({ productIds, productFilter: { filter: productFilter }, sku });
    if (response.status >= 200 && response.status < 400) {
      dispatch(setSuccessMessage(response.data?.message, response.data?.statusTitle));

      const paramsByFilter = filters.toJS().reduce((acc, { columnName, value }) => {
        acc[columnName] = value;
        return acc;
      }, {});

      const orderBy = convertSortingObjectToUrlParams(sorting);

      const params = {
        ...paramsByFilter,
        categoryId,
        attributeSetId: attributeSetId ? Number(attributeSetId) : undefined,
        orderBy,
        limit,
        offset,
      };
      dispatch(asyncActions.getListAsync(params));
    } else {
      dispatch(setErrorMessage(response.data?.errors, response.data?.statusTitle));
    }
    setIsLoading(false);
  };


  return (
    <Box sx={{ gap: '8px', display: 'flex', flexDirection: 'column' }}>
      <form>
        <Select
          options={options}
          variant="outlined"
          color="dark"
          disabled={!selection.size && !filters.size}
          handleChange={handleChange}
          placeholder=""
          label="Змінити"
          value={selectedValue}
        />
        {selectedValue?.value === 'update' && (
          <Controller
            name="TORPsku"
            control={control}
            render={({ field }) => {
              const input = generateInput(field);
              const meta = generateMeta(errors.TORPsku?.message, !!touchedFields.TORPsku);
              return (
                <Box sx={{ marginTop: '8px' }}>
                  <TextField
                    label="SKU торпу"
                    fullWidth
                    variant="outlined"
                    color="dark"
                    input={input}
                    required
                    meta={meta}
                    validation
                  />
                </Box>
              );
            }}
            rules={{ required: t('Это обязательное поле') }}
          />
        )}
        <ButtonWithIconAndCircularProgress
          fullWidth
          darkTheme
          text={t('Применить')}
          isLoading={isLoading}
          type="button"
          onClick={handleSubmit(onSubmit)}
          color="primary"
          component="button"
          sx={{ margin: '28px auto', p: BUTTON_SIZE.outlinedSmall }}
          disabled={!selectedValue}
        >
          <SaveIcon />
        </ButtonWithIconAndCircularProgress>
        <WarningNotificationText text="Оберіть товари у таблиці, для того, щоб створити, видалити, або додати товари у ТОРП" />
      </form>
    </Box>
  );
};


export default TORPsButtonGroups;
