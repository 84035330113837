// Core
import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { Field } from 'redux-form/immutable';
// Parts
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import { Tooltip } from '@mui/material';
import RenderCheckbox from './RenderCheckbox';
import FieldWithFeatureDisabled from './FieldWithFeatureDisabled';
import TextField from '../../../../../components/_Form/TextField/TextField/TextField';
import FieldWithClearButton from '../../../../../containers/FieldWithClearButton/FieldWithClearButton';
import { formName } from '../../../../../../_helpers/data/pages/contentMenagement/contentProducts/contentProductEditForm';
import HelperText from './HelperText';
// Engine
import { accessList } from '../../../../../../engine/config/access';
// Helpers
import { useAccessList } from '../../../../../hooks/useAccessList';
import { useStyles } from '../../../../../hooks/useStyles';
import ContentProductEditStyles from '../ContentProductEditStyles';
import { useIsValueWasChanged } from '../hooks/useIsValueWasChanged';
import { getCheckboxName, inputValueEmpty } from '../ContentProductEdit';

function RenderFormFieldInput(props) {
  const {
    margin, massEdit, input, meta, disabled,
    isRecommended, required, featureDisabled,
    refresh, onRefresh, type, label, id, fieldFormat,
    changeFieldValue, setTouchField, isFiledPercentWarning, tooltip,
  } = props;
  const touched = meta.touched;
  const error = !!(meta.touched && meta.error);
  const { isValueChanged, value, showChangedMessage } = useIsValueWasChanged(input, fieldFormat);
  const checkboxName = getCheckboxName(input.name);
  const [willBeClear, setWillBeClear] = useState(false);
  const isPriceField = fieldFormat === 'price';
  const hasProductPricesImportAccess = useAccessList(accessList.productsImportPrices);
  const isDefaultDisabled = disabled || featureDisabled?.disabled;
  const isDisablePrice = isDefaultDisabled || !hasProductPricesImportAccess;
  const classes = useStyles(ContentProductEditStyles);
  const isDisabled = isPriceField ? isDisablePrice : isDefaultDisabled;

  useEffect(() => {
    changeFieldValue(checkboxName, isValueChanged);
  }, [changeFieldValue, checkboxName, isValueChanged]);

  const classNames = cx(classes.formControl, {
    [classes.formControlHelperTextChange]: showChangedMessage,
    [classes.changedFormControl]: showChangedMessage,
    [classes.formControlRecommended]: isRecommended && inputValueEmpty(value) && !isDisabled,
  });

  const onClick = (!touched && !isDisabled) ? () => setTouchField(input.name) : null;
  const inputProps = {
    ...input,
    onChange: (event) => {
      switch (fieldFormat) {
        case 'unsignedInt': {
          const numberValue = Number(event.target.value.replace(/[^0-9]/g, ''));
          if (numberValue <= 2147483647) {
            input.onChange(numberValue.toString());
          }
          break;
        }
        case 'price': {
          input.onChange(event.value);
          break;
        }
        default: {
          input.onChange(event);
        }
      }
    },
  };

  return (
    <FormControl
      error={error}
      className={classNames}
      margin={margin}
      onClick={onClick}
    >
      <Grid container>
        <Grid item xs={1}>
          {massEdit && (
            <Field
              component={RenderCheckbox}
              error={error}
              name={checkboxName}
              disabled={isDisabled}
              inputValue={input.value}
              setWillBeClear={setWillBeClear}
              isValueChanged={isValueChanged}
            />
          )}
        </Grid>
        <Grid item xs={10}>
          <Tooltip title={tooltip}>
            <FieldWithClearButton
              refresh={refresh}
              onRefresh={onRefresh}
              formName={formName}
              {...input}
              name={input.name.toString()}
              disabled={isDisabled}
            >
              <FieldWithFeatureDisabled showTooltip={featureDisabled?.showTooltip}>
                <TextField
                  disabled={isDisabled}
                  input={inputProps}
                  fullWidth
                  label={label}
                  isRecommended={isRecommended}
                  required={required}
                  id={id}
                  isPrice={isPriceField}
                  isRed={isFiledPercentWarning}
                  type={type}
                />
              </FieldWithFeatureDisabled>
            </FieldWithClearButton>
            <HelperText
              error={meta?.error}
              isError={error}
              isValueClear={willBeClear}
              isValueChanged={showChangedMessage}
              touched={touched}
            />
          </Tooltip>
        </Grid>
        <Grid item xs={1} />
      </Grid>
    </FormControl>
  );
}

export default RenderFormFieldInput;
