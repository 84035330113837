
export const types = {
  SET_SPECIAL_PRICES_FILES_CURRENT_PAGE: 'SET_SPECIAL_PRICES_FILES_CURRENT_PAGE',
  SET_SPECIAL_PRICES_FILES_FILTERS: 'SET_SPECIAL_PRICES_FILES_FILTERS',
  SET_SPECIAL_PRICES_FILES_PAGE_SIZE: 'SET_SPECIAL_PRICES_FILES_PAGE_SIZE',
  SET_SPECIAL_PRICES_FILES_SORTING: 'SET_SPECIAL_PRICES_FILES_SORTING',
  SET_SPECIAL_PRICES_FILES_HISTORY_LIST: 'SET_SPECIAL_PRICES_FILES_LIST',
  SET_SPECIAL_PRICES_FILES_HISTORY_CURRENT_PAGE: 'SET_SPECIAL_PRICES_FILES_CURRENT_PAGE',
  SET_SPECIAL_PRICES_FILES_HISTORY_FILTERS: 'SET_SPECIAL_PRICES_FILES_FILTERS',
  SET_SPECIAL_PRICES_FILES_HISTORY_PAGE_SIZE: 'SET_SPECIAL_PRICES_FILES_PAGE_SIZE',
  SET_SPECIAL_PRICES_FILES_HISTORY_SORTING: 'SET_SPECIAL_PRICES_FILES_SORTING',
  RESET_SPECIAL_PRICES_FILES_FILTERS: 'RESET_SPECIAL_PRICES_FILES_FILTERS',
  SET_SPECIAL_PRICES_FILES_SETTING_STATUS: 'SET_SPECIAL_PRICES_FILES_SETTING_STATUS',
  SET_SPECIAL_PRICES_FILES_SETTING_STATUS_PENDING: 'SET_SPECIAL_PRICES_FILES_SETTING_STATUS_PENDING',
  SET_SPECIAL_PRICES_FILES_HISTORY_POPUP_OPEN: 'SET_SPECIAL_PRICES_FILES_HISTORY_POPUP_OPEN',
};
