
export function FileStatusIcon() {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="31" height="31" rx="4.5" stroke="#303030" strokeOpacity="0.12" />
      <path
        d="M13.6667 8.06641C12.6357 8.06641 11.8 8.90214 11.8 9.93307V14.793C12.1008 14.7079 12.4128 14.6495 12.7333 14.6207V9.93307C12.7333 9.41761 13.1512 8.99974 13.6667 8.99974H17.4V12.2664C17.4 13.0396 18.0268 13.6664 18.8 13.6664H22.0667V21.1331C22.0667 21.6485 21.6488 22.0664 21.1333 22.0664H17.7736C17.6029 22.4003 17.3967 22.7131 17.16 22.9997H21.1333C22.1643 22.9997 23 22.164 23 21.1331V13.1197C23 12.7484 22.8525 12.3923 22.5899 12.1297L18.9367 8.47646C18.6741 8.21391 18.318 8.06641 17.9467 8.06641H13.6667ZM21.8734 12.7331H18.8C18.5423 12.7331 18.3333 12.5241 18.3333 12.2664V9.19304L21.8734 12.7331ZM17.4 19.7331C17.4 22.0527 15.5196 23.9331 13.2 23.9331C10.8804 23.9331 9 22.0527 9 19.7331C9 17.4135 10.8804 15.5331 13.2 15.5331C15.5196 15.5331 17.4 17.4135 17.4 19.7331ZM15.3966 18.0031C15.2144 17.8208 14.9189 17.8208 14.7367 18.0031L12.2667 20.4731L11.6633 19.8698C11.4811 19.6875 11.1856 19.6875 11.0034 19.8698C10.8211 20.052 10.8211 20.3475 11.0034 20.5297L11.9367 21.4631C12.1189 21.6453 12.4144 21.6453 12.5966 21.4631L15.3966 18.6631C15.5789 18.4808 15.5789 18.1853 15.3966 18.0031Z"
        fill="#303030"
      />
    </svg>
  );
}
