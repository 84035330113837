// Core
import { apply, call, put } from 'redux-saga/effects';
import { getRefreshToken } from 'axios-jwt';
// Engine
import { api } from '../../../../config/api';

// Instruments
import { userActionAsync } from '../asyncAction';

export function* callLogOutWorker({ payload: serverLogOut }) {
  const refreshToken = getRefreshToken();
  if (refreshToken && serverLogOut) {
    yield apply(api, api.user.logout, [{ refreshToken }]);
  }
  yield put(userActionAsync.authCleanUp());
  yield call(() => window.location.reload());
}
