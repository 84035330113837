import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import { GridSettingsIcon } from '../Icons/GridSettingsIcon';

export function ButtonGridSettings({
  onClick, putSetting, sx, tooltipText, ...props
}) {
  const handleOpen = (e) => {
    document.getElementById('main-wrapper').style.overflow = 'hidden';
    putSetting(e);
    setTimeout(() => {
      document.getElementById('main-wrapper').style.removeProperty('overflow');
    }, 2000);
  };

  return (
    <Tooltip title={tooltipText || ''} placement="bottom-end">
      <Button
        disableRipple
        sx={{
          transition: 'transform 0.1s ease-in-out',
          '&:active': {
            transform: 'scale(0.8)',
          },
          '&:hover': {
            backgroundColor: 'inherit',
          },
          backgroundColor: 'inherit',
          color: 'inherit',
          p: 0,
          ...sx,
        }}
        {...props}
        onClick={handleOpen}
      >
        <GridSettingsIcon />
      </Button>
    </Tooltip>
  );
}
