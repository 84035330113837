import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { api } from '../../../../engine/config/api';
import { ButtonWithTooltip } from '../../../components/Buttons/ButtonWithIcon/Light/ButtonWithTooltip';
import { HistoryIcon } from '../../../icons/HistoryIcon';
import { EditIcon } from '../../../icons/EditIcon';
import { AdminSingleTariffHistoryModal } from './AdminSingleTariffHistoryModal';
import { AdminSingleTariffFormModal } from './AdminSingleTariffFormModal';
import ModalConfirm from '../../../containers/Modals/ModalConfirm';
import Loading from '../../../components/Loading/Loading';
import { setErrorMessage, setSuccessMessage } from '../../../../engine/core/ui/saga/asyncActionNotificationMessages';

export function AdminSingleTariffEditBlock() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [singleTariffValue, setSingleTariffValue] = useState(0);
  const [singleTariffModalOpen, setSingleTariffModalOpen] = useState(false);
  const [singleTariffFormModalOpen, setSingleTariffFormModalOpen] = useState(false);
  const [singleTariffPending, setSingleTariffPending] = useState(false);

  const handleSingleTariffModalClose = () => {
    setSingleTariffModalOpen(false);
  };
  const handleSingleTariffFormModalClose = () => {
    setSingleTariffFormModalOpen(false);
  };

  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      singleTariff: singleTariffValue,
    },
  });

  const { reset } = methods;

  const {
    handleSubmit,
    formState: { isValid },
  } = methods;

  const getCurrentSingleTariff = async () => {
    try {
      setSingleTariffPending(true);
      const response = await api.alloExpress.getSingleTariff();
      if (response.status === 200) {
        setSingleTariffValue(response.data?.value);
        reset({ singleTariff: response.data?.value });
      } else {
        dispatch(setErrorMessage(response.data.status, t('Ошибка')));
      }
    } finally {
      setSingleTariffPending(false);
    }
  };

  const [pendingData, setPendingData] = useState(null);
  const [confirmOpen, setConfirmOpen] = useState(false);

  const onSubmit = (data) => {
    setSingleTariffFormModalOpen(false);
    setPendingData(data);
    setConfirmOpen(true);
  };

  const handleConfirmYes = async () => {
    try {
      setSingleTariffPending(true);
      const { singleTariff } = pendingData;
      const { value } = singleTariff;
      const response = await api.alloExpress.createOrUpdateSingleTariff(value);
      if (response.status === 200) {
        dispatch(setSuccessMessage(response.data.statusTitle, t('Успех')));
      } else {
        dispatch(setErrorMessage(response.data.statusTitle, t('Ошибка')));
      }
      setSingleTariffValue(response.data.value);
    } finally {
      setSingleTariffPending(false);
      setConfirmOpen(false);
    }
  };

  const handleConfirmNo = () => {
    setConfirmOpen(false);
  };

  useEffect(() => {
    getCurrentSingleTariff();
  }, []);

  if (singleTariffPending) {
    return (
      <Box sx={{
        width: '100%', height: '100%', display: 'flex', justifyContent: 'center',
      }}
      >
        <Loading isLoading={singleTariffPending} />
      </Box>
    );
  }

  return (
    <Paper sx={{ padding: '20px 16px' }} elevation={2}>
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
      >
        <Box>
          <h4 className={'ma-0'}>Єдиний тариф на доставку</h4>
        </Box>
        <Box
          sx={{ display: 'flex', gap: 1 }}
        >
          <ButtonWithTooltip
            tooltipText={'Історія змін'}
            onClick={() => {
              setSingleTariffModalOpen(true);
            }}
          >
            <HistoryIcon />
          </ButtonWithTooltip>
          <ButtonWithTooltip
            tooltipText={'Редагування тарифу'}
            onClick={() => {
              setSingleTariffFormModalOpen(true);
            }}
          >
            <EditIcon />
          </ButtonWithTooltip>
        </Box>
      </Box>
      <Box>
        <Divider sx={{ mb: '20px', mt: '10px' }} />
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'left',
          gap: 2,
        }}
        >
          <Box>
            <Typography
              color={'rgba(48, 48, 48, 0.72)'}
              fontSize={'12px'}
            >Поточний тариф:
            </Typography>
          </Box>
          <Box>
            <Typography fontSize={'12px'} fontWeight={700}>{singleTariffValue} ₴</Typography>
          </Box>
        </Box>
      </Box>
      <AdminSingleTariffHistoryModal title={'Історія змін'} open={singleTariffModalOpen} close={handleSingleTariffModalClose} />
      <Box>
        <FormProvider {...methods}>
          <form>
            <AdminSingleTariffFormModal
              handleSubmit={handleSubmit}
              onSubmit={onSubmit}
              title={'Редагування тарифу'}
              isValid={isValid}
              singleTariffValue={singleTariffValue}
              open={singleTariffFormModalOpen}
              close={handleSingleTariffFormModalClose}
            />
            <ModalConfirm
              modalMaxWidth={'sm'}
              onConfirm={handleConfirmYes}
              showModal={confirmOpen}
              onCancel={handleConfirmNo}
              title={'Зміна тарифу на доставку'}
            >
              <Typography><b>Увага!</b> Після збереження нового тарифу, він буде застосований для всіх партнерів, які підключили доставку &quot;Алло Express&quot;</Typography>
            </ModalConfirm>
          </form>
        </FormProvider>
      </Box>
    </Paper>
  );
}
