import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { Link as MaterialLink } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import ModeOutlinedIcon from '@mui/icons-material/ModeOutlined';
import Tooltip from '@mui/material/Tooltip';
import { useNavigate } from 'react-router';
import PageHeader from '../../components/PageHeader/PageHeader';
import ConnectModal from './components/ConnectModal';
import { api, baseUrl, publicUrl } from '../../../engine/config/api';
import { selectors } from '../../../engine/config/selectors';
import { useUserRole } from '../../hooks/useUserRole';
import { userRoles } from '../../../engine/config/userRoles';
import ChoosePartnerAndServiceModal from './components/ChoosePartnerAndServiceModal';
import { partnerAsyncAction } from '../../../engine/core/_partners/saga/asyncAction';
import Loading from '../../components/Loading/Loading';
import { partnerStatuses } from '../../../engine/config/partnerStatuses';
import { pageLinks } from '../../../engine/config/routes';
import { IconButtonStyles } from '../../components/_Table/TableCell/Styles';
import { ConfirmCancelRequestModal } from './components/ConfirmCancelRequestModal';
import { getStatusColor, isPartnerCanCancelRequest, serviceRequestsStatuses } from '../../../engine/config/serviceRequestsStatuses';
import { setErrorMessage, setSuccessMessage } from '../../../engine/core/ui/saga/asyncActionNotificationMessages';
import { COLOR } from '../../containers/App/AppStyles';
import { ConfirmDisableModal } from './components/ConfirmDisableModal';
import CancelModal from '../../containers/Modals/CancelModal/CancelModal';
import { validateRequiredFields } from '../../../_helpers/validationsForm';
import { AdminSingleTariffEditBlock } from './components/AdminSingleTariffEditBlock';
import { AlloExpressMainPage } from './AlloExpressMainPage';

function AlloExpress() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userPartnerHashId = useSelector(selectors.user.userPartnerHashId);
  const isAdminRole = useUserRole(userRoles.admin);
  const isPartnerActiveRole = useUserRole(userRoles.partnerActive);
  const { partnerStatus } = useSelector(selectors.user.userInfo);
  const [pageIsLoading, setPageIsLoading] = useState(true);
  const [isConnectFromModalOpen, setIsConnectFromModalOpen] = useState(false);
  const [isPartnerAndServiceSelectModalOpen, setIsPartnerAndServiceSelectModalOpen] = useState(false);
  const [partner, setPartner] = useState(false);
  const [serviceName, setServiceName] = useState('ALLO_EXPRESS');
  const [partnerServiceRequestStatus, setPartnerServiceRequestStatus] = useState(null);
  const [confirmCancelRequestModalOpen, setConfirmCancelRequestModalOpen] = useState(false);
  const [confirmDisableRequestModalOpen, setConfirmDisableRequestModalOpen] = useState(false);
  const navigate = useNavigate();
  const [requiredFieldsModalOpen, setRequiredFieldModalOpen] = useState(false);
  const [invalidFields, setInvalidFields] = useState([]);
  const config = useSelector(selectors.user.configurations);
  const alloExpressData = config.get('ALLO_EXPRESS_ALLOW_TO_USE');
  const isPartnerCanSeeSingleTariff = (alloExpressData && alloExpressData.has('allow_to_see_single_tariff'))
    ? alloExpressData.get('allow_to_see_single_tariff') : false;

  const handlePartnerAndServiceModalClose = (selectedPartnerHash, selectedService) => {
    if (selectedPartnerHash) {
      setServiceName(selectedService);
      api.partner.getDataByHashId(selectedPartnerHash)
        .then((response) => {
          setPartner(response.data);
          setIsPartnerAndServiceSelectModalOpen(false);
          const result = validateRequiredFields(response.data, t);
          if (result.length > 0) {
            setInvalidFields(result);
            setRequiredFieldModalOpen(true);
          } else if (isAdminRole) {
            setIsConnectFromModalOpen(true);
          }
        });
    } else {
      setIsPartnerAndServiceSelectModalOpen(!isPartnerAndServiceSelectModalOpen);
    }
  };
  const handlePartnerAndServiceModalOpen = () => {
    setIsPartnerAndServiceSelectModalOpen(true);
  };

  const handleOpenModal = () => {
    if (isAdminRole) {
      handlePartnerAndServiceModalOpen();
    }
    if (isPartnerActiveRole) {
      const result = validateRequiredFields(partner, t);
      if (result.length > 0) {
        setInvalidFields(result);
        setRequiredFieldModalOpen(true);
      } else {
        setIsConnectFromModalOpen(true);
      }
    }
  };
  const handleCloseModal = () => setIsConnectFromModalOpen(false);
  const paragraphColor = 'rgba(48, 48, 48, 0.87)';
  const smallTextColor = 'rgba(48, 48, 48, 0.72)';


  const fetchData = async () => {
    if (isPartnerActiveRole && !isAdminRole) {
      setPageIsLoading(true);
      try {
        const partnerResponse = await api.partner.getDataByHashId(userPartnerHashId);
        const serviceRequestResponse = await api.partnerServiceRequests.getPartnerServiceRequestStatus(userPartnerHashId);
        setPartner(partnerResponse.data);
        setPartnerServiceRequestStatus(serviceRequestResponse.data);
      } finally {
        setPageIsLoading(false);
      }
    } else {
      setPageIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [isPartnerActiveRole, isAdminRole, userPartnerHashId, isConnectFromModalOpen]);

  const onCancelRequest = (cancellationReason, cancellationComment) => {
    if (userPartnerHashId) {
      setPageIsLoading(true);
      api.partnerServiceRequests.changeServiceRequestStatus(userPartnerHashId, partnerServiceRequestStatus.id, {
        reason: cancellationReason,
        comment: cancellationComment,
        status: serviceRequestsStatuses.CANCELED,
      }).then((response) => {
        if (response.status === 200) {
          dispatch(setSuccessMessage(response.data.status, t('Успех')));
        } else {
          dispatch(setErrorMessage(response.data.status, t('Ошибка')));
        }
        fetchData();
      }).catch(() => {
        dispatch(setErrorMessage('error', t('Ошибка')));
      }).finally(() => {
        setPageIsLoading(false);
      });
    }
  };

  const changeStatusRequest = (status) => {
    setPageIsLoading(true);
    api.partnerServiceRequests.changeServiceRequestStatus(userPartnerHashId, partnerServiceRequestStatus.id, {
      status,
    }).then((response) => {
      if (response.status === 200) {
        dispatch(setSuccessMessage(response.data.status, t('Успех')));
      } else {
        dispatch(setErrorMessage(response.data.status, t('Ошибка')));
      }
    }).catch(() => {
      dispatch(setErrorMessage('error', t('Ошибка')));
    }).finally(() => {
      setPageIsLoading(false);
      fetchData();
    });
  };

  useEffect(() => {
    if (isAdminRole) {
      dispatch(partnerAsyncAction.getPartnersForServiceRequestList());
    }
  }, [isConnectFromModalOpen]);

  const isShowConnectButton = () => {
    if (isAdminRole) {
      return true;
    }

    if (isPartnerActiveRole && (partnerStatus !== partnerStatuses.suspended && partnerStatus !== partnerStatuses.problematic)) {
      if (!partnerServiceRequestStatus) {
        return true;
      }
      if (isPartnerActiveRole && partnerServiceRequestStatus.statusValue === serviceRequestsStatuses.CANCELED && !partnerServiceRequestStatus?.hasTheContract) {
        return true;
      }
    }

    return false;
  };

  if (pageIsLoading) {
    return (
      <>
        <PageHeader title={t('Алло Express')} />
        <Box sx={{
          width: '100%', height: '100%', display: 'flex', justifyContent: 'center',
        }}
        >
          <Loading isLoading={pageIsLoading} />
        </Box>
      </>
    );
  }

  if (isPartnerCanSeeSingleTariff && (!pageIsLoading && isPartnerActiveRole && partnerServiceRequestStatus)) {
    return (
      <AlloExpressMainPage />
    );
  }

  if (isPartnerActiveRole && partnerServiceRequestStatus) {
    return (
      <>
        <PageHeader title={t('АЛЛО Express')} />
        <Box marginTop={'5px'}>
          <Grid container>
            <Grid
              item
              md={8}
              lg={8}
              xl={9}
              sm={12}
              xs={12}
            >
              <Paper style={{ padding: '20px' }} elevation={2}>
                <Box sx={{ flexGrow: 1 }}>
                  <Box
                    borderBottom={'1px solid rgba(48, 48, 48, 0.12)'}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    marginBottom={1}
                    padding={0}
                    height={52}
                  >
                    <Typography color={'#303030'} fontWeight={700} fontSize={16} gutterBottom>{'Послуга доставки "АЛЛО Express"'}</Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                      {isPartnerCanCancelRequest(partnerServiceRequestStatus.statusValue) && (
                      <Button
                        variant="outlined"
                        color="primary"
                        sx={{ marginRight: '15px' }}
                        onClick={() => {
                          setConfirmCancelRequestModalOpen(true);
                        }}
                      >Скасувати заявку
                      </Button>
                      )}
                      {partnerServiceRequestStatus.statusValue === serviceRequestsStatuses.CONNECTED && (
                        <Button
                          variant="outlined"
                          color="primary"
                          sx={{ marginRight: '15px' }}
                          onClick={() => {
                            setConfirmDisableRequestModalOpen(true);
                          }}
                        >Вимкнути послугу
                        </Button>
                      )}
                      {partnerServiceRequestStatus.statusValue !== serviceRequestsStatuses.CANCELED && (
                        <Link to={pageLinks.shippingIntegration.edit(userPartnerHashId)}>
                          <Tooltip title={'Перегляд і редагування заявки'}>
                            <IconButtonStyles sx={{ borderRadius: '5px', padding: '5px' }} color="inherit" size="large">
                              <ModeOutlinedIcon sx={{ p: '2px' }} />
                            </IconButtonStyles>
                          </Tooltip>
                        </Link>
                      )}
                    </Box>
                  </Box>
                  {partnerServiceRequestStatus.statusValue === serviceRequestsStatuses.CANCELED && (
                    <>
                      {partnerServiceRequestStatus?.isChangeByAdmin ? (
                        <Typography
                          display={'inline-block'}
                          fontWeight={400}
                          fontSize={'14px'}
                          color={paragraphColor}
                        >Заявка на підключення скасована Адміністратором.
                        </Typography>
                      ) : (
                        <Typography
                          display={'inline-block'}
                          fontWeight={400}
                          fontSize={'14px'}
                          color={paragraphColor}
                        >Заявка на підключення скасована.
                        </Typography>
                      )}
                      <Grid marginTop={'20px'} container spacing={2}>
                        {partnerServiceRequestStatus.reason !== '' && (
                          <>
                            <Grid item lg={2} xs={12}>
                              <Typography fontSize={'12px'} color={COLOR.black['54']}>Причина: </Typography>
                            </Grid>
                            <Grid item lg={10} xs={12}>
                              <Typography fontSize={'14px'}>{partnerServiceRequestStatus.reason}</Typography>
                            </Grid>
                          </>
                        )}
                        {partnerServiceRequestStatus.cancelComment !== '' && (
                          <>
                            <Grid item lg={2} xs={12}>
                              <Typography fontSize={'12px'} color={COLOR.black['54']}>Коментар: </Typography>
                            </Grid>
                            <Grid item lg={10} xs={12}>
                              <Typography fontSize={'14px'}>{partnerServiceRequestStatus.cancelComment}</Typography>
                            </Grid>
                          </>
                        )}
                      </Grid>
                      <Box marginTop={'20px'}>
                        <Typography
                          display={'inline-block'}
                          fontWeight={400}
                          fontSize={'14px'}
                          color={paragraphColor}
                        >Якщо з’явилися питання або потрібна допомога, зверніться в наш контактний центр:
                        </Typography>
                        <Box>
                          <span role={'img'} aria-label={''}>☎️</span>
                          <Typography
                            display={'inline-block'}
                            fontWeight={400}
                            fontSize={'14px'}
                            color={paragraphColor}
                          ><a href={'tel:0800200900'}> {'\u00A0'} 0 800 200 900</a>
                          </Typography>
                        </Box>
                        <Box marginTop={'15px'} marginBottom={'10px'}>
                          <MaterialLink href={`${baseUrl}/docs/about_allo_express.pdf`} rel="noreferrer" target="_blank">
                            <Button style={{ marginRight: '10px', color: '#303030DD', borderColor: '#3030301F' }} variant="outlined">Про АЛЛО Express</Button>
                          </MaterialLink>
                          {isShowConnectButton() && !partnerServiceRequestStatus?.isChangeByAdmin && (
                            <Button
                              disabled={pageIsLoading}
                              onClick={() => {
                                if (!partnerServiceRequestStatus.hasTheContract) {
                                  changeStatusRequest(serviceRequestsStatuses.AWAITING_APPROVAL);
                                } else {
                                  changeStatusRequest(serviceRequestsStatuses.SIGNING_THE_CONTRACT);
                                }
                              }}
                              variant="contained"
                            >Підключити
                            </Button>
                          )}
                        </Box>
                      </Box>
                    </>
                  )}
                  {partnerServiceRequestStatus.statusValue === serviceRequestsStatuses.DISABLED && (
                    <>
                      <Box>
                        <Typography
                          fontWeight={400}
                          fontSize={'14px'}
                        >Послуга в статусі {'"'}<Box component={'span'} color={getStatusColor(partnerServiceRequestStatus.statusValue)}>Вимкнено</Box>{'"'}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography
                          fontWeight={400}
                          fontSize={'14px'}
                        >Доставка АЛЛО Express була вимкнена для ваших товарів на сайті  <MaterialLink href={'https://allo.ua'} rel="noreferrer" target="_blank">allo.ua</MaterialLink>
                        </Typography>
                      </Box>
                      <Divider sx={{ mt: '10px' }} />
                      <Box marginTop={'20px'}>
                        <Typography
                          display={'inline-block'}
                          fontWeight={400}
                          fontSize={'14px'}
                          color={paragraphColor}
                        >Якщо з’явилися питання або потрібна допомога, зверніться в наш контактний центр:
                        </Typography>
                        <Box>
                          <span role={'img'} aria-label={''}>☎️</span>
                          <Typography
                            display={'inline-block'}
                            fontWeight={400}
                            fontSize={'14px'}
                            color={paragraphColor}
                          ><a href={'tel:0800200900'}> {'\u00A0'} 0 800 200 900</a>
                          </Typography>
                        </Box>
                        <Box marginTop={'15px'} marginBottom={'10px'}>
                          <MaterialLink href={`${baseUrl}/docs/about_allo_express.pdf`} rel="noreferrer" target="_blank">
                            <Button style={{ marginRight: '10px', color: '#303030DD', borderColor: '#3030301F' }} variant="outlined">Про АЛЛО Express</Button>
                          </MaterialLink>
                          { !partnerServiceRequestStatus?.isChangeByAdmin && (
                            <Button
                              disabled={pageIsLoading}
                              onClick={() => {
                                changeStatusRequest(serviceRequestsStatuses.CONNECTED);
                              }}
                              variant="contained"
                            >Підключити
                            </Button>
                          )}
                        </Box>
                      </Box>
                    </>
                  )}
                  {partnerServiceRequestStatus.statusValue === serviceRequestsStatuses.SIGNING_THE_CONTRACT && (
                    <>
                      <Box>
                        <Typography>Послуга в статусі <Box component={'span'} color={getStatusColor(partnerServiceRequestStatus.statusValue)}>Підписання документів</Box>. Підпишіть всі надіслані документи у <Link target={'_blank'} variant="body2" to={'https://vchasno.ua/'}><span style={{ color: '#5285CC', textDecorationLine: 'underline' }}>Вчасно</span></Link>, щоб користуватися усіма сервісами цієї послуги.</Typography>
                      </Box>
                    </>
                  )}
                  {partnerServiceRequestStatus.statusValue === serviceRequestsStatuses.AWAITING_APPROVAL && (
                    <>
                      <Box>
                        <span role={'img'} aria-label={''}>⏰</span>
                        <Typography
                          display={'inline-block'}
                          fontWeight={400}
                          fontSize={'14px'}
                          color={paragraphColor}
                        > {'\u00A0'} Заявка на послугу створена. Ми обробимо її протягом 3 днів і повідомимо вас про наступні кроки
                        </Typography>
                      </Box>
                      <Box marginTop={'20px'}>
                        <Typography
                          display={'inline-block'}
                          fontWeight={400}
                          fontSize={'14px'}
                          color={paragraphColor}
                        >Якщо з’явилися питання або потрібна допомога, зверніться в наш контактний центр:
                        </Typography>
                        <Box>
                          <span role={'img'} aria-label={''}>☎️</span>
                          <Typography
                            display={'inline-block'}
                            fontWeight={400}
                            fontSize={'14px'}
                            color={paragraphColor}
                          ><a href={'tel:0800200900'}> {'\u00A0'} 0 800 200 900</a>
                          </Typography>
                        </Box>
                        <Box marginTop={'15px'} marginBottom={'10px'}>
                          <MaterialLink href={`${baseUrl}/docs/about_allo_express.pdf`} rel="noreferrer" target="_blank">
                            <Button style={{ marginRight: '10px', color: '#303030DD', borderColor: '#3030301F' }} variant="outlined">Про АЛЛО Express</Button>
                          </MaterialLink>
                        </Box>
                      </Box>
                    </>
                  )}
                  {partnerServiceRequestStatus.statusValue === serviceRequestsStatuses.CONNECTED && (
                    <>
                      <Box>
                        <span role={'img'} aria-label={''}>✅</span>
                        <Typography
                          fontWeight={400}
                          fontSize={'14px'}
                          component={'span'}
                          sx={{ ml: '5px' }}
                        >Послуга підключена
                        </Typography>
                        <Box>
                          <Typography
                            display={'inline-block'}
                            fontWeight={400}
                            fontSize={'14px'}
                            color={paragraphColor}
                          >Тепер ви можете користуватися усіма сервісами цієї послуги
                          </Typography>
                        </Box>
                      </Box>
                      <Box marginTop={'15px'} marginBottom={'10px'}>
                        {isShowConnectButton() && !partnerServiceRequestStatus?.isChangeByAdmin && (
                          <Button
                            disabled={pageIsLoading}
                            onClick={() => {
                              if (!partnerServiceRequestStatus.hasTheContract) {
                                changeStatusRequest(serviceRequestsStatuses.AWAITING_APPROVAL);
                              } else {
                                changeStatusRequest(serviceRequestsStatuses.SIGNING_THE_CONTRACT);
                              }
                            }}
                            variant="contained"
                          >Підключити
                          </Button>
                        )}
                      </Box>
                    </>
                  )}
                </Box>
              </Paper>
              <Paper style={{ marginTop: '10px', padding: '20px 20px 0px 20px' }} elevation={2}>
                <h4 className={'ma-0'}>Чому обирають АЛЛО Express?</h4>
                <Grid container spacing={2}>
                  <Grid item md={2.4} xs={6}>
                    <Box sx={{ display: 'flex' }}>
                      <img style={{ margin: 'auto' }} height={'70px'} src={`${publicUrl}/allo-express/free-delivery.png`} alt={'alt'} />
                    </Box>
                    <div style={{ padding: '10px' }}>
                      <Typography fontWeight={400} fontSize={'13px'} color={paragraphColor} textAlign={'center'}>Доставка до 30 кг</Typography>
                    </div>
                  </Grid>
                  <Grid item md={2.4} xs={6}>
                    <Box sx={{ display: 'flex' }}>
                      <img style={{ margin: 'auto' }} height={'70px'} src={`${publicUrl}/allo-express/courier.png`} alt={'alt'} />
                    </Box>
                    <div style={{ padding: '10px' }}>
                      <Typography fontWeight={400} fontSize={'13px'} color={paragraphColor} textAlign={'center'}>{'Можливість передати посилку в пункти прийому'}</Typography>
                    </div>
                  </Grid>
                  <Grid item md={2.4} xs={6}>
                    <Box sx={{ display: 'flex' }}>
                      <img style={{ margin: 'auto' }} height={'70px'} src={`${publicUrl}/allo-express/free-return.png`} alt={'alt'} />
                    </Box>
                    <div style={{ padding: '10px' }}>
                      <Typography fontWeight={400} fontSize={'13px'} color={paragraphColor} textAlign={'center'}>Безкоштовне повернення товарів</Typography>
                    </div>
                  </Grid>
                  <Grid item md={2.4} xs={6}>
                    <Box sx={{ display: 'flex' }}>
                      <img style={{ margin: 'auto' }} height={'70px'} src={`${publicUrl}/allo-express/buyer-inform.png`} alt={'alt'} />
                    </Box>
                    <div style={{ padding: '10px' }}>
                      <Typography fontWeight={400} fontSize={'13px'} color={paragraphColor} textAlign={'center'}>Інформування покупця про прибуття замовлення</Typography>
                    </div>
                  </Grid>
                  <Grid item md={2.4} xs={6}>
                    <Box sx={{ display: 'flex' }}>
                      <img style={{ margin: 'auto' }} height={'70px'} src={`${publicUrl}/allo-express/support.png`} alt={'alt'} />
                    </Box>
                    <div style={{ padding: '10px' }}>
                      <Typography fontWeight={400} fontSize={'13px'} color={paragraphColor} textAlign={'center'}>Супровід на етапах підключення та подальшої роботи</Typography>
                    </div>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
          {isPartnerCanCancelRequest(partnerServiceRequestStatus.statusValue) && (
          <ConfirmCancelRequestModal
            isSendingData={pageIsLoading}
            open={confirmCancelRequestModalOpen}
            onModalOpen={setConfirmCancelRequestModalOpen}
            onCancelRequest={onCancelRequest}
          />
          )}
          {partnerServiceRequestStatus.statusValue === serviceRequestsStatuses.CONNECTED && (
            <>
              <ConfirmDisableModal
                isSendingData={pageIsLoading}
                open={confirmDisableRequestModalOpen}
                onModalOpen={setConfirmDisableRequestModalOpen}
                onDisableRequest={() => {
                  changeStatusRequest(serviceRequestsStatuses.DISABLED);
                }}
              />
            </>
          ) }
        </Box>
      </>
    );
  }

  return (
    <>
      <PageHeader title={t('АЛЛО Express')} />
      <Box marginTop={'5px'}>
        <Grid container spacing={'10px'}>
          <Grid item xs={12}>
            <Paper style={{ padding: '20px' }} elevation={2}>
              <Box sx={{ flexGrow: 1 }}>
                <Grid container>
                  <Grid
                    item
                    md={8}
                    lg={8}
                    xl={9}
                    sm={12}
                    xs={12}
                    order={{
                      xs: 2, xl: 1, md: 1, lg: 1,
                    }}
                  >
                    <h4 className={'ma-0'}>Підключіть доставку АЛЛО Express у пункті видачі АЛЛО</h4>
                    <Typography fontWeight={400} fontSize={'14px'} color={paragraphColor}>Отримайте можливість доставляти замовлення до 30 кг. Дізнайтеся про умови і переваги АЛЛО Express, підпишіть <Link variant="body2" to={'/service_contract'} target="_blank"><span style={{ color: '#5285CC', textDecorationLine: 'underline' }}>договір</span></Link> та підключайтеся до нашої послуги з доставки.</Typography>
                    <Box marginTop={'15px'} marginBottom={'10px'}>
                      <MaterialLink href={`${baseUrl}/docs/about_allo_express.pdf`} rel="noreferrer" target="_blank">
                        <Button style={{ marginRight: '10px', color: '#303030DD', borderColor: '#3030301F' }} variant="outlined">Про АЛЛО Express</Button>
                      </MaterialLink>
                      {isShowConnectButton() && !partnerServiceRequestStatus?.isChangeByAdmin && (
                        <Button
                          disabled={pageIsLoading}
                          onClick={handleOpenModal}
                          variant="contained"
                        >Підключити
                        </Button>
                      )}
                    </Box>
                  </Grid>
                  <Grid item xl={3} lg={4} md={4} sm={12} xs={12} order={{ xs: 1, xl: 2, md: 2 }}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <img height={'100%'} width={'200px'} src={`${publicUrl}/allo-express/allo-express.png`} alt={'alt'} />
                    </div>
                  </Grid>
                  <Grid item sm={12} order={{ xs: 3 }}>
                    <Divider />
                    <Typography marginTop={'15px'} color={smallTextColor} fontSize={'12px'}>*Впродовж тестового періоду послуга доставки товару до точки видачі магазину АЛЛО партнерам надається безкоштовно</Typography>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper sx={{ padding: '20px 20px 0px 20px' }} elevation={2}>
              <h4 className={'ma-0'}>Чому обирають АЛЛО Express?</h4>
              <Grid container spacing={2}>
                <Grid item md={2.4} xs={6}>
                  <div style={{ display: 'flex' }}>
                    <img style={{ margin: 'auto' }} height={'70px'} src={`${publicUrl}/allo-express/free-delivery.png`} alt={'alt'} />
                  </div>
                  <div style={{ padding: '10px' }}>
                    <Typography fontWeight={400} fontSize={'13px'} color={paragraphColor} textAlign={'center'}>Доставка до 30 кг</Typography>
                  </div>
                </Grid>
                <Grid item md={2.4} xs={6}>
                  <div style={{ display: 'flex' }}>
                    <img style={{ margin: 'auto' }} height={'70px'} src={`${publicUrl}/allo-express/courier.png`} alt={'alt'} />
                  </div>
                  <div style={{ padding: '10px' }}>
                    <Typography fontWeight={400} fontSize={'13px'} color={paragraphColor} textAlign={'center'}>{'Можливість передати посилку в пункти прийому'}</Typography>
                  </div>
                </Grid>
                <Grid item md={2.4} xs={6}>
                  <div style={{ display: 'flex' }}>
                    <img style={{ margin: 'auto' }} height={'70px'} src={`${publicUrl}/allo-express/free-return.png`} alt={'alt'} />
                  </div>
                  <div style={{ padding: '10px' }}>
                    <Typography fontWeight={400} fontSize={'13px'} color={paragraphColor} textAlign={'center'}>Безкоштовне повернення товарів</Typography>
                  </div>
                </Grid>
                <Grid item md={2.4} xs={6}>
                  <div style={{ display: 'flex' }}>
                    <img style={{ margin: 'auto' }} height={'70px'} src={`${publicUrl}/allo-express/buyer-inform.png`} alt={'alt'} />
                  </div>
                  <div style={{ padding: '10px' }}>
                    <Typography fontWeight={400} fontSize={'13px'} color={paragraphColor} textAlign={'center'}>Інформування покупця про прибуття замовлення</Typography>
                  </div>
                </Grid>
                <Grid item md={2.4} xs={6}>
                  <div style={{ display: 'flex' }}>
                    <img style={{ margin: 'auto' }} height={'70px'} src={`${publicUrl}/allo-express/support.png`} alt={'alt'} />
                  </div>
                  <div style={{ padding: '10px' }}>
                    <Typography fontWeight={400} fontSize={'13px'} color={paragraphColor} textAlign={'center'}>Супровід на етапах підключення та подальшої роботи</Typography>
                  </div>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          {isPartnerCanSeeSingleTariff && isAdminRole && (
            <Grid item xs={12}>
              <AdminSingleTariffEditBlock />
            </Grid>
          )}
        </Grid>
      </Box>
      <CancelModal
        sendText={t('Заповнити')}
        titleText={t('Увага! В "Анкеті партнера" незаповнені наступні поля')}
        text={(
          <>
            {invalidFields.map((field, index) => (
              <Typography fontSize={'14px'} key={index}>{field}</Typography>
            ))}
            <Typography sx={{ mt: '10px' }} fontSize={'14px'}>Будь ласка, зверніться до вашого менеджера підтримки, для завершення заповнення даних.</Typography>
          </>
        )}
        cancelText={t('Отменить')}
        closeModal={requiredFieldsModalOpen}
        handleCancel={() => {
          if (isAdminRole) {
            navigate(pageLinks.partner.edit(partner.hashId));
          } else {
            navigate('/profile/partner');
          }
        }}
        handleCloseModal={() => {
          setRequiredFieldModalOpen(false);
        }}
      />
      { partner && (<ConnectModal serviceName={serviceName} partner={partner} open={isConnectFromModalOpen} onClose={handleCloseModal} />) }
      { isPartnerAndServiceSelectModalOpen && (<ChoosePartnerAndServiceModal open={isPartnerAndServiceSelectModalOpen} onModalClose={handlePartnerAndServiceModalClose} />)}
      <Box />
    </>
  );
}

export default AlloExpress;
