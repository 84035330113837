import { useEffect } from 'react';
import { useSelector } from 'react-redux';
// Selectors
import { selectors } from '../../engine/config/selectors';
import { useUserSomeRole } from './useUserSomeRole';
import { userRoles } from '../../engine/config/userRoles';

export function useChat() {
  const userInfo = useSelector(selectors.user.userInfo);
  const isAdmin = useUserSomeRole([
    userRoles.admin,
    userRoles.contentManager,
    userRoles.callCenter,
    userRoles.salesManager,
    userRoles.creditDepartmentManager,
    userRoles.partnerDisabled,
    userRoles.manager,
    userRoles.partnerCanceled,
  ]);
  useEffect(() => {
    const callbackScript = document.createElement('script');
    const loaderScript = document.createElement('script');

    if (isAdmin || !userInfo.rolesList) {
      return () => {
        if (document.body.contains(callbackScript)) {
          document.body.removeChild(callbackScript);
        }
        if (document.body.contains(loaderScript)) {
          document.body.removeChild(loaderScript);
        }
      };
    }

    callbackScript.text = `
      window.alloCallback = function() {
        AlloWidget.init({
          companyId: "allo-marketplace",
          m_id: "${userInfo.partnerMid || ''}",
          email: "${userInfo.email || ''}",
          partnername: "${userInfo.name || ''}"
        });
      }
    `;
    document.body.appendChild(callbackScript);

    loaderScript.text = `
      (function(d, s, id) {
        var js, fjs = d.getElementsByTagName(s)[0];
        js = d.createElement(s);
        js.id = id;
        js.src = "https://nord.allo.ua/init.js";
        fjs.parentNode.insertBefore(js, fjs);
      })(document, 'script', 'allo-widget');
    `;
    document.body.appendChild(loaderScript);

    return () => {
      document.body.removeChild(callbackScript);
      document.body.removeChild(loaderScript);
    };
  }, [userInfo.rolesList]);
}
