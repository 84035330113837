import { getIn, Map } from 'immutable';
import { stateKeys } from './reducer';
import { pageSizes } from '../../config/globalConfig';
import { commonStateKeys } from '../../config/stateKeys';

export const specialPricesSelector = {
  currentPage: state => getIn(state, [stateKeys.controller, commonStateKeys.currentPage], 0),
  filters: state => getIn(state, [stateKeys.controller, commonStateKeys.filters], Map()),
  pageSize: state => getIn(state, [stateKeys.controller, commonStateKeys.pageSize], pageSizes[0]),
  sorting: state => getIn(state, [stateKeys.controller, commonStateKeys.sorting], Map()),
  historySidebar: state => getIn(state, [stateKeys.controller, stateKeys.historyPopupOpen], false),
  settingStatus: state => getIn(state, [stateKeys.controller, commonStateKeys.settingStatus], false),
  settingPending: state => getIn(state, [stateKeys.controller, commonStateKeys.settingPending], false),
};
