import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Link from '@mui/material/Link';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { uk } from 'date-fns/locale';
import { ButtonWithTooltip } from '../../../components/Buttons/ButtonWithIcon/Light/ButtonWithTooltip';
import { HistoryIcon } from '../../../icons/HistoryIcon';
import { EditIcon } from '../../../icons/EditIcon';
import ModalConfirm from '../../../containers/Modals/ModalConfirm';
import { PartnerSingleTariffFormModal } from './PartnerSingleTariffFormModal';
import { api } from '../../../../engine/config/api';
import { setErrorMessage, setSuccessMessage } from '../../../../engine/core/ui/saga/asyncActionNotificationMessages';
import Loading from '../../../components/Loading/Loading';
import { selectors } from '../../../../engine/config/selectors';
import { PartnerSingleTariffHistoryModal } from './PartnerSingleTariffHistoryModal';

export function PartnerSingleTariffEditBlock() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [singleTariffModalOpen, setSingleTariffModalOpen] = useState(false);
  const [singleTariffFormModalOpen, setSingleTariffFormModalOpen] = useState(false);
  const [singleTariffPending, setSingleTariffPending] = useState(false);
  const [partnerSingleTariff, setPartnerSingleTariff] = useState(null);
  const [pendingData, setPendingData] = useState(null);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const userPartnerHashId = useSelector(selectors.user.userPartnerHashId);
  const tomorrow = (new Date()).setDate(new Date().getDate() + 1);
  const formattedDate = format(tomorrow, 'dd.MM.yyyy', { locale: uk });

  const getCurrentSingleTariff = async () => {
    try {
      setSingleTariffPending(true);
      const response = await api.alloExpress.getPartnerSingleTariffWithHistory(userPartnerHashId);
      if (response.status === 200) {
        setPartnerSingleTariff(response.data);
      }
    } finally {
      setSingleTariffPending(false);
    }
  };

  useEffect(() => {
    getCurrentSingleTariff();
  }, []);
  const handleSingleTariffModalClose = () => {
    setSingleTariffModalOpen(false);
  };
  const handleSingleTariffFormModalClose = () => {
    setSingleTariffFormModalOpen(false);
  };

  const onSubmit = (data) => {
    setSingleTariffFormModalOpen(false);
    setPendingData(data);
    setConfirmOpen(true);
  };

  const handleConfirmYes = async () => {
    try {
      setSingleTariffPending(true);
      const response = await api.alloExpress
        .createOrUpdatePartnerSingleTariff(userPartnerHashId, pendingData);

      if (response.status === 200) {
        dispatch(setSuccessMessage(response.data.statusTitle, t('Успех')));
      } else {
        dispatch(setErrorMessage(response.data.statusTitle, t('Ошибка')));
      }
    } finally {
      setSingleTariffPending(false);
      setConfirmOpen(false);
      getCurrentSingleTariff();
    }
  };

  const handleConfirmNo = () => {
    setConfirmOpen(false);
  };

  if (singleTariffPending) {
    return (
      <Box sx={{
        width: '100%', height: '100%', display: 'flex', justifyContent: 'center',
      }}
      >
        <Loading isLoading={singleTariffPending} />
      </Box>
    );
  }

  return (
    <Paper sx={{ padding: '20px 16px', height: '317px' }} elevation={2}>
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
      >
        <Box>
          <h4 className={'ma-0'}>Єдиний тариф на доставку</h4>
        </Box>
        <Box
          sx={{ display: 'flex', gap: 1 }}
        >
          <ButtonWithTooltip
            tooltipText={'Історія змін'}
            onClick={() => {
              setSingleTariffModalOpen(true);
            }}
          >
            <HistoryIcon />
          </ButtonWithTooltip>
          <ButtonWithTooltip
            tooltipText={'Редагування платника'}
            onClick={() => {
              setSingleTariffFormModalOpen(true);
            }}
          >
            <EditIcon />
          </ButtonWithTooltip>
        </Box>
      </Box>
      <Box>
        <Divider sx={{ mb: '20px', mt: '10px' }} />
        <Grid container spacing={1}>
          <Grid item md={4} lg={3} xs={6} sm={4}>
            <Box>
              <Typography
                color={'rgba(48, 48, 48, 0.72)'}
                fontSize={'12px'}
              >Платник:
              </Typography>
            </Box>
          </Grid>
          <Grid item md={8} lg={9} xs={6} sm={8}>
            <Box>
              <Typography fontSize={'12px'} fontWeight={700}>{partnerSingleTariff?.partnerTariffLabel}</Typography>
            </Box>
          </Grid>
          <Grid item md={4} lg={3} xs={6} sm={4}>
            <Box>
              <Typography
                color={'rgba(48, 48, 48, 0.72)'}
                fontSize={'12px'}
              >Поточний тариф:
              </Typography>
            </Box>
          </Grid>
          <Grid item md={8} lg={9} xs={6} sm={8}>
            <Box>
              <Typography fontSize={'12px'} fontWeight={700}>{partnerSingleTariff?.tariffValue} ₴</Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <PartnerSingleTariffHistoryModal
        title={'Історія змін'}
        open={singleTariffModalOpen}
        close={handleSingleTariffModalClose}
        items={partnerSingleTariff?.tariffHistory}
      />
      <Box>
        <PartnerSingleTariffFormModal
          onSubmit={onSubmit}
          title={'Редагування тарифу'}
          singleTariffValue={partnerSingleTariff?.partnerTariffValue}
          open={singleTariffFormModalOpen}
          close={handleSingleTariffFormModalClose}
        />
        <ModalConfirm
          modalMaxWidth={'sm'}
          onConfirm={handleConfirmYes}
          showModal={confirmOpen}
          onCancel={handleConfirmNo}
          title={'Зміна платника за доставку перевізником "АЛЛО Express"'}
        >
          {pendingData === 'seller' && (
            <>
              <Typography variant="body2">
                <b>Увага!</b> Після збереження змін – оплачувати вартість доставки по всім замовленням «АЛЛО Express» буде <b>продавець</b>.
              </Typography>
              <Typography variant="body2" gutterBottom>
                Для покупця доставка замовлення буде безкоштовною.
              </Typography>
            </>
          )}
          {pendingData === 'buyer' && (
            <>
              <Typography variant="body2">
                <b>Увага!</b> Після збереження змін – оплачувати вартість доставки по всім замовленням «АЛЛО Express» буде <b>покупець</b>.
              </Typography>
              <Typography variant="body2" gutterBottom>
                Вартість доставки буде відображатись на сайті <Link target={'_blank'} href={'https://allo.ua'}>allo.ua</Link>, але оплата буде відбуватись при отриманні замовлення.
              </Typography>
            </>
          )}
          <Typography variant="body2">
            *Збережені дані будуть застосовані на сайті <Link target={'_blank'} href={'https://allo.ua'}>allo.ua</Link> з {formattedDate}
          </Typography>
        </ModalConfirm>
      </Box>
    </Paper>
  );
}
