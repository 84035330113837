// Core
import { all, takeEvery } from 'redux-saga/effects';
// Types
import { types } from '../types';
// Workers
import {
  callSetToLocalDataWorker, callPutSettingsWorker,
} from './workers';

import { asyncTypes } from './asyncTypes';


function* watchSetCurrentPage() {
  yield takeEvery(types.SET_SPECIAL_PRICES_FILES_CURRENT_PAGE, callSetToLocalDataWorker);
}
function* watchSetPageSize() {
  yield takeEvery(types.SET_SPECIAL_PRICES_FILES_PAGE_SIZE, callSetToLocalDataWorker);
}
function* watchSetFilters() {
  yield takeEvery(types.SET_SPECIAL_PRICES_FILES_FILTERS, callSetToLocalDataWorker);
}
function* watchSetSorting() {
  yield takeEvery(types.SET_SPECIAL_PRICES_FILES_SORTING, callSetToLocalDataWorker);
}

function* watchPutSettingAsync() {
  yield takeEvery(asyncTypes.PUT_SPECIAL_PRICES_FILES_SETTINGS_ASYNC, callPutSettingsWorker);
}


export function* watchersSpecialPricesFiles() {
  yield all([
    watchSetCurrentPage(),
    watchSetPageSize(),
    watchSetFilters(),
    watchSetSorting(),
    watchPutSettingAsync(),
  ]);
}
