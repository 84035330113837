import Box from '@mui/material/Box';
import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import { VirtualTable } from '@devexpress/dx-react-grid-material-ui';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import RightSlideModal from '../../../containers/Modals/RightSlideModal/RightSlideModal';
import DxTable from '../../../components/_Table/DxTable/DxTable';
import { api } from '../../../../engine/config/api';
import { getStatusColor } from '../../../../engine/config/serviceRequestsStatuses';
import Loading from '../../../components/Loading/Loading';
import { setErrorMessage } from '../../../../engine/core/ui/saga/asyncActionNotificationMessages';

const disableFiltering = [
  { columnName: 'createdAt', filteringEnabled: false },
  { columnName: 'value', filteringEnabled: false },
  { columnName: 'prevValue', filteringEnabled: false },
  { columnName: 'changedBy', filteringEnabled: false },
];

const CustomCell = (props) => {
  const { column, row } = props;
  if (column.name === 'changeLabelAfter') {
    return (
      <VirtualTable.Cell>
        <Typography fontSize={'13px'} color={getStatusColor(row.changeValueAfter)}>
          {row.changeLabelAfter}
        </Typography>
      </VirtualTable.Cell>
    );
  }
  if (column.name === 'changeLabelBefore') {
    return (
      <VirtualTable.Cell>
        <Typography fontSize={'13px'} color={getStatusColor(row.changeValueBefore)}>
          {row.changeLabelBefore}
        </Typography>
      </VirtualTable.Cell>
    );
  }
  return <VirtualTable.Cell {...props} />;
};
export function PartnerAlloExpressHistoryModal({
  title, open, close, partnerServiceRequestId, partnerHash,
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [items, setItems] = useState([]);
  const [pending, setPending] = useState(false);
  const fetchData = async () => {
    try {
      setPending(true);
      const historyResponse = await api.partnerServiceRequests.getRequestHistoryList(
        partnerHash, partnerServiceRequestId, {
          limit: 100,
          offset: 0,
        },
      );
      if (historyResponse.status === 200) {
        setItems(historyResponse.data.items);
      } else {
        dispatch(setErrorMessage(historyResponse.data.status, t('Ошибка')));
      }
    } finally {
      setPending(false);
    }
  };

  useEffect(() => {
    if (open) {
      fetchData();
    }
  }, [open]);

  const columns = [
    {
      name: 'changeDate',
      type: 'text',
      title: t('Дата зміни'),
      getCellValue: row => row.changeDate,
    },
    {
      name: 'changeLabelBefore',
      title: t('Статус до зміни'),
      cellComponent: row => row.changeLabelBefore,
    },
    {
      name: 'changeLabelAfter',
      title: t('Статус після зміни'),
      cellComponent: row => row.changeLabelAfter,
    },
    {
      name: 'user',
      type: 'text',
      title: t('Користувач, який змінював тариф'),
      getCellValue: row => row.user,
    },
    {
      name: 'cancelReason',
      type: 'text',
      title: t('Причина скасування'),
      getCellValue: row => row.cancelReason,
    },
    {
      name: 'cancelComment',
      type: 'text',
      title: t('Коментар'),
      getCellValue: row => row.cancelComment,
    },
  ];

  return (
    <RightSlideModal
      fullScreen
      margin={0}
      open={open}
      scroll="body"
      onClose={close}
      PaperProps={{
        style: {
          width: '75%',
          height: '100%',
          position: 'fixed',
          right: 0,
          top: 0,
          margin: 0,
          borderRadius: 0,
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <Box
          borderBottom={'1px solid rgba(48, 48, 48, 0.12)'}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            pl: '20px', pr: '20px', pt: '14px', pb: '14px',
          }}
        >
          <Typography fontWeight={700} color="#303030" fontSize={18}>{title}</Typography>
          <IconButton
            sx={{ p: '0px' }}
            edge="start"
            color="inherit"
            onClick={close}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            overflow: 'scroll',
            p: 2,
          }}
        >
          {pending ? (
            <Box sx={{
              width: '100%', height: '100%', display: 'flex', justifyContent: 'center',
            }}
            >
              <Loading isLoading={pending} />
            </Box>
          ) : (
            <DxTable
              customCellComponent={CustomCell}
              staticHeightBlock={'100%'}
              screenHeightFull
              filteringExtensions={disableFiltering}
              rows={items}
              columns={columns}
              pageSizes={[10, 25, 50]}
            />
          )}
        </Box>
      </Box>
    </RightSlideModal>
  );
}
