import Box from '@mui/material/Box';
import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import RightSlideModal from '../../../containers/Modals/RightSlideModal/RightSlideModal';
import Loading from '../../../components/Loading/Loading';
import { api } from '../../../../engine/config/api';
import DxTable from '../../../components/_Table/DxTable/DxTable';
import { setErrorMessage } from '../../../../engine/core/ui/saga/asyncActionNotificationMessages';

const disableFiltering = [
  { columnName: 'createdAt', filteringEnabled: false },
  { columnName: 'value', filteringEnabled: false },
  { columnName: 'prevValue', filteringEnabled: false },
  { columnName: 'changedBy', filteringEnabled: false },
];

export function AdminSingleTariffHistoryModal({ title, open, close }) {
  const dispatch = useDispatch();
  const [singleTariffPending, setSingleTariffPending] = useState(false);
  const [items, setItems] = useState([]);
  const { t } = useTranslation();
  const fetchHistory = async () => {
    try {
      setSingleTariffPending(true);
      const response = await api.alloExpress.getSingleTariffHistory();
      if (response.status === 200) {
        setItems(response.data);
      } else {
        dispatch(setErrorMessage(response.data.status, t('Ошибка')));
      }
    } finally {
      setSingleTariffPending(false);
    }
  };

  useEffect(() => {
    if (open) {
      fetchHistory();
    }
  }, [open]);

  const columns = [
    {
      name: 'createdAt',
      type: 'text',
      title: t('Дата зміни'),
      getCellValue: row => row.createdAt,
    },
    {
      name: 'prevValue',
      type: 'text',
      title: t('Тариф до зміни'),
      getCellValue: row => row.prevValue,
    },
    {
      name: 'value',
      type: 'text',
      title: t('Новий'),
      getCellValue: row => row.value,
    },
    {
      name: 'changedBy',
      type: 'text',
      title: t('Користувач, який змінював тариф'),
      getCellValue: row => row.changedBy,
    },
  ];

  return (
    <RightSlideModal
      fullScreen
      margin={0}
      open={open}
      scroll="body"
      onClose={close}
      PaperProps={{
        style: {
          width: '75%',
          height: '100%',
          position: 'fixed',
          right: 0,
          top: 0,
          margin: 0,
          borderRadius: 0,
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <Box
          borderBottom={'1px solid rgba(48, 48, 48, 0.12)'}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            pl: '20px', pr: '20px', pt: '14px', pb: '14px',
          }}
        >
          <Typography fontWeight={700} color="#303030" fontSize={18}>{title}</Typography>
          <IconButton
            sx={{ p: '0px' }}
            edge="start"
            color="inherit"
            onClick={close}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            overflow: 'scroll',
            p: 2,
          }}
        >{singleTariffPending ? (
          <Box sx={{
            width: '100%', height: '100%', display: 'flex', justifyContent: 'center',
          }}
          >
            <Loading isLoading={singleTariffPending} />
          </Box>
        ) : (
          <DxTable
            staticHeightBlock={'100%'}
            screenHeightFull
            filteringExtensions={disableFiltering}
            rows={items}
            columns={columns}
            pageSizes={[10, 25, 50]}
          />
        )}
        </Box>
      </Box>
    </RightSlideModal>
  );
}
