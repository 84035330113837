import { useTranslation } from 'react-i18next';
import React from 'react';
import { useDispatch } from 'react-redux';
import { AddPromotionButton } from '../compoents/AddPromotionButton';
import { usePromotionsList } from './_hooks/usePromotionsList';
import PromotionsTable from './PromotionsTable';
import { useAccessList } from '../../../hooks/useAccessList';
import { accessList } from '../../../../engine/config/access';
import { actions } from '../../../../engine/core/promotions/actions';
import { asyncActions } from '../../../../engine/core/promotions/saga/asyncActions';
import { TableControlPanel } from './table/TableControlPanel';
import { ButtonClearFilter } from '../../../components/Buttons/ButtonClearFilter';
import { ButtonGridSettings } from '../../../components/Buttons/ButtonGridSettings';
import { PageHeaderLight } from '../../../components/PageHeader/PageHeaderLight';
import { ModalContextProvider } from '../../../containers/Context/UseModalContext';
import { CategoriesModal } from './CategoriesModal';

export function PromotionListPage() {
  usePromotionsList();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const hasAccessToCreate = useAccessList(accessList.promotions_create);
  const resetFilters = () => {
    dispatch(actions.setFilters([]));
  };

  const putSetting = () => {
    dispatch(asyncActions.putSettingsAsync());
  };

  return (
    <>
      <PageHeaderLight title={t('Акції')}>
        {hasAccessToCreate && (
          <AddPromotionButton />
        )}
      </PageHeaderLight>
      <TableControlPanel>
        <ButtonGridSettings
          tooltipText={t('Налаштування таблиці')}
          putSetting={putSetting}
          sx={{ m: '-5px 5px 0px 5px' }}
        />
        <ButtonClearFilter
          tooltipText={t('Сбросить фильтры')}
          onClick={resetFilters}
        />
      </TableControlPanel>
      <ModalContextProvider>
        <PromotionsTable />
        <CategoriesModal title={'Список категорій Алло в акції'} />
      </ModalContextProvider>
    </>
  );
}
