
export function EditIcon() {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="31" height="31" rx="4.5" stroke="#303030" strokeOpacity="0.12" />
      <g clipPath="url(#clip0_5226_104880)">
        <path d="M23.7279 11.2418L20.8014 8.30042C20.608 8.10801 20.3463 8 20.0735 8C19.8007 8 19.539 8.10801 19.3457 8.30042L8.97094 18.6602L8.0237 22.7482C7.99103 22.8977 7.99215 23.0525 8.02698 23.2015C8.06182 23.3504 8.12949 23.4897 8.22505 23.6092C8.32061 23.7286 8.44165 23.8252 8.57932 23.8919C8.71699 23.9586 8.86782 23.9937 9.02079 23.9946C9.09207 24.0018 9.16389 24.0018 9.23517 23.9946L13.3681 23.0474L23.7279 12.6976C23.9203 12.5042 24.0283 12.2425 24.0283 11.9697C24.0283 11.6969 23.9203 11.4352 23.7279 11.2418ZM12.8696 22.15L8.99587 22.9626L9.87829 19.1637L17.6406 11.4313L20.6319 14.4225L12.8696 22.15ZM21.2999 13.6997L18.3087 10.7084L20.0436 8.98342L22.985 11.9747L21.2999 13.6997Z" fill="#303030" stroke="#303030" strokeWidth="0.5" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_5226_104880">
          <rect width="24" height="24" fill="white" transform="translate(4 4)" />
        </clipPath>
      </defs>
    </svg>
  );
}
