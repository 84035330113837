import Box from '@mui/material/Box';
import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RightSlideModal from '../../../containers/Modals/RightSlideModal/RightSlideModal';

export function PartnerSingleTariffFormModal({
  title, open, close, onSubmit, singleTariffValue,
}) {
  const [value, setValue] = useState(singleTariffValue);

  return (
    <RightSlideModal
      fullScreen
      margin={0}
      open={open}
      scroll="body"
      onClose={close}
      PaperProps={{
        style: {
          width: '360px',
          height: '100%',
          position: 'fixed',
          right: 0,
          top: 0,
          margin: 0,
          borderRadius: 0,
        },
      }}
    >
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
      >
        <Box
          borderBottom={'1px solid rgba(48, 48, 48, 0.12)'}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            pl: '20px', pr: '20px', pt: '14px', pb: '14px',
          }}
        >
          <Box>
            <Typography fontWeight={700} color={'#303030'} fontSize={18}>{title}</Typography>
          </Box>
          <IconButton
            sx={{ p: '0px' }}
            edge="start"
            color="inherit"
            onClick={close}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Box sx={{ p: '20px', flex: '1 1 auto' }}>
          <Box sx={{ mt: 2 }}>
            <FormControl
              component="fieldset"
              sx={{
                width: '100%',
                border: '1px solid #ccc',
                borderRadius: '8px',
                padding: '16px',
                position: 'relative',
              }}
            >
              <FormLabel
                component="legend"
                sx={{
                  position: 'absolute',
                  top: '-10px',
                  left: '12px',
                  backgroundColor: 'white',
                  px: 1,
                  fontSize: '14px',
                  color: 'gray',
                }}
              >
                <Typography color={'rgba(0, 0, 0, 0.6)'} fontSize={'14px'}>
                  Оберіть платника
                </Typography>
              </FormLabel>
              <RadioGroup
                value={value}
                onChange={(e) => {
                  setValue(e.target.value);
                }}
                name="payer"
                sx={{
                  '& .MuiFormControlLabel-root': {
                    pointerEvents: 'none',
                  },
                  '& .MuiRadio-root, & .MuiFormControlLabel-label': {
                    pointerEvents: 'auto',
                  },
                }}
              >
                <FormControlLabel
                  sx={{
                    '& .MuiFormControlLabel-label': {
                      fontSize: '14px',
                    },
                  }}
                  value="buyer"
                  control={<Radio size={'small'} />}
                  label="Покупець"
                />
                <FormControlLabel
                  sx={{
                    '& .MuiFormControlLabel-label': {
                      fontSize: '14px',
                    },
                  }}
                  value="seller"
                  control={<Radio size={'small'} />}
                  label="Продавець"
                />
              </RadioGroup>
            </FormControl>
          </Box>
        </Box>
        <Box
          sx={{
            p: '20px',
            borderTop: '1px solid rgba(48, 48, 48, 0.12)',
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
            <Button sx={{ marginRight: '8px' }} variant="outlined" onClick={close}>Скасувати</Button>
            <Button
              disabled={singleTariffValue === value}
              variant="contained"
              onClick={() => {
                onSubmit(value);
              }}
            >Підтвердити
            </Button>
          </Box>
        </Box>
      </Box>
    </RightSlideModal>
  );
}
