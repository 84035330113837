import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router';
import Typography from '@mui/material/Typography';
import PageHeader from '../../components/PageHeader/PageHeader';
import { actions } from '../../../engine/core/partners/partnerServiceRequests/actions';
import { initialState, stateKeys } from '../../../engine/core/partners/partnerServiceRequests/reducer';
import ResetFiltersButton from '../../components/_Table/DxTable/components/ResetFilterButton';
import { PartnerServiceRequestsTable } from './components/table/PartnerServiceRequestsTable';
import { selectors } from '../../../engine/config/selectors';
import { usePartnerServiceRequestList } from './_hooks/usePartnerServiceRequestList';
import ExportButton from './components/table/ExportButton';
import ConnectModal from '../ShippingIntegration/components/ConnectModal';
import ChoosePartnerAndServiceModal from '../ShippingIntegration/components/ChoosePartnerAndServiceModal';
import { api } from '../../../engine/config/api';
import { partnerAsyncAction } from '../../../engine/core/_partners/saga/asyncAction';
import { asyncActions } from '../../../engine/core/partners/partnerServiceRequests/saga/asyncActions';
import CancelModal from '../../containers/Modals/CancelModal/CancelModal';
import { pageLinks } from '../../../engine/config/routes';
import { validateRequiredFields } from '../../../_helpers/validationsForm';

function PartnerServiceRequestListPage() {
  usePartnerServiceRequestList();

  const [isConnectFromModalOpen, setIsConnectFromModalOpen] = useState(false);
  const [isPartnerAndServiceSelectModalOpen, setIsPartnerAndServiceSelectModalOpen] = useState(false);
  const [partner, setPartner] = useState(false);
  const currentPage = useSelector(selectors.partnersServiceRequests.currentPage);
  const limit = useSelector(selectors.partnersServiceRequests.pageSize);
  const [serviceName, setServiceName] = useState('ALLO_EXPRESS');

  const isLoading = useSelector(selectors.partnersServiceRequests.isLoading);
  const filters = useSelector(selectors.partnersServiceRequests.filters);
  const sorting = useSelector(selectors.partnersServiceRequests.sorting);
  const isDisabledResetButton = isLoading || !(filters.size || sorting.size);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const resetFilters = useCallback(() => {
    dispatch(actions.setFilters(initialState.get(stateKeys.partnersServiceRequestsList).get(stateKeys.filters).toJS()));
    dispatch(actions.setSorting(initialState.get(stateKeys.partnersServiceRequestsList).get(stateKeys.sorting).toJS()));
    dispatch(actions.setCurrentPage(initialState.get(stateKeys.partnersServiceRequestsList).get(stateKeys.currentPage)));
  }, [dispatch, isConnectFromModalOpen, isPartnerAndServiceSelectModalOpen]);

  useEffect(() => {
    dispatch(partnerAsyncAction.getPartnersForServiceRequestList());
  }, [isConnectFromModalOpen]);

  const navigate = useNavigate();
  const [requiredFieldsModalOpen, setRequiredFieldModalOpen] = useState(false);
  const [invalidFields, setInvalidFields] = useState([]);

  const handlePartnerAndServiceModalClose = (selectedPartnerHash, selectedService) => {
    if (selectedPartnerHash) {
      setServiceName(selectedService);
      api.partner.getDataByHashId(selectedPartnerHash)
        .then((response) => {
          setPartner(response.data);
          setIsPartnerAndServiceSelectModalOpen(false);
          const result = validateRequiredFields(response.data, t);
          if (result.length > 0) {
            setInvalidFields(result);
            setRequiredFieldModalOpen(true);
          } else {
            setIsConnectFromModalOpen(true);
          }
        });
    } else {
      setIsPartnerAndServiceSelectModalOpen(!isPartnerAndServiceSelectModalOpen);
    }
  };

  const handlePartnerAndServiceModalOpen = () => {
    setIsPartnerAndServiceSelectModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsConnectFromModalOpen(false);
    const offset = limit * currentPage;
    const params = {
      limit,
      offset,
    };
    dispatch(asyncActions.getListAsync(params));
  };

  return (
    <>
      <PageHeader title={t('Подключенные услуги')}>
        <Button
          variant="contained"
          onClick={handlePartnerAndServiceModalOpen}
        >{t('Создать заявку')}
        </Button>
        <ExportButton />
        <ResetFiltersButton pending={isLoading} onReset={resetFilters} disabled={isDisabledResetButton} />
      </PageHeader>
      <PartnerServiceRequestsTable />
      { partner && (<ConnectModal serviceName={serviceName} partner={partner} open={isConnectFromModalOpen} onClose={handleCloseModal} />) }
      <ChoosePartnerAndServiceModal open={isPartnerAndServiceSelectModalOpen} onModalClose={handlePartnerAndServiceModalClose} />
      <CancelModal
        sendText={t('Заповнити')}
        titleText={t('Увага! В "Анкеті партнера" незаповнені наступні поля')}
        text={(
          <>
            {invalidFields.map((field, index) => (
              <Typography fontSize={'14px'} key={index}>{field}</Typography>
            ))}
            <Typography sx={{ mt: '10px' }} fontSize={'14px'}>Будь ласка, зверніться до вашого менеджера підтримки, для завершення заповнення даних.</Typography>
          </>
        )}
        cancelText={t('Отменить')}
        closeModal={requiredFieldsModalOpen}
        handleCancel={() => {
          const link = pageLinks.partner.edit(partner.hashId);
          navigate(link);
        }}
        handleCloseModal={() => {
          setRequiredFieldModalOpen(false);
        }}
      />
    </>
  );
}

export default PartnerServiceRequestListPage;
